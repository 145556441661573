import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import React from 'react';
import { useAuth } from 'src/contexts/AuthenticationContext';
import { themeCreator } from './base';

export const ThemeContext = React.createContext(
  // (themeName: string): void => { } 
  undefined
);

const ThemeProviderWrapper = (props) => {
  const { themeName, _setThemeName } = useAuth()
  const theme = themeCreator(themeName);
  const setThemeName = (themeNam: string): void => {
    _setThemeName(themeNam);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider >
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
};

export default ThemeProviderWrapper;
