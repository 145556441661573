import { Box } from '@mui/material';
import HeaderSearch from './Search';
import HeaderNotifications from './Notifications';
import HeaderMasking from './Masking';

function HeaderButtons() {
  return (
    <Box sx={{ mr: 1 }}>
      
      {/* <HeaderSearch /> */}
      {/* <Box sx={{ mx: .5 }} component="span">
        <HeaderNotifications />
      </Box> */}
      <HeaderMasking/>
    </Box>
  );
}

export default HeaderButtons;
