import { Box, List, ListSubheader, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getMenuList } from 'src/recoil/selectors';
import SidebarMenuItem from './item';
import { MenuItem } from './items';

import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { useAuth } from 'src/contexts/AuthenticationContext';

const MenuWrapper = styled(List)(
  ({ theme }) => `
    // margin-bottom: ${theme.spacing(1)};
    margin-bottom: 30px;
    padding: 0;
    height: 55px;
    cursor: pointer;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(2)} ${theme.spacing(2)};
    }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      // color: ${theme.sidebar.menuItemHeadingColor};
      color: #fff;
      padding: ${theme.spacing(0.8, 2)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(List)(
  ({ theme }) => `
    &.MuiList-root {
      padding: 0;

      .MuiList-root .MuiList-root .MuiListItem-root .MuiButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 2px ${theme.spacing(2)};
    
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(13)};
          padding-top: ${theme.spacing(0.8)};
          padding-bottom: ${theme.spacing(0.8)};
          position: relative;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(4)};

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(9)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(26)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.sidebar.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(22)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px ${theme.spacing(0)};

            .MuiButton-root {
              font-size: ${theme.typography.pxToRem(13)};
              padding: ${theme.spacing(0.5, 2, 0.5, 6.5)};

              &.Mui-active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBg};
              }
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({
  items,
  path
}: {
  items: MenuItem[];
  path: string;
}): JSX.Element => (
  <SubMenuWrapper>
    {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
}): Array<JSX.Element> => {
  const key = item.name;

  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false
          },
          path
        )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
      />
    );
  }

  return ev;
};

function SidebarMenu() {
  const location = useLocation();
  const { themeName } = useAuth()
  const [alertCont, setAlertCont] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  
  /////////////////하위 메뉴 모달용 리소스
  const [menuIndex, setMenuIndex] = useState(0);
  const [menuModalType, setMenuModalType] = useState('');
  const [menuModalItems, setMenuModalItems] = useState([]);
  const [menuModalOpen, setMenuModalOpen] = useState(false);
  const [menuModalPositionTop, setMenuModalPositionTop] = useState(null);
  const [menuModalHeading, setMenuModalHeading] = useState('');
  /////////////////하위 메뉴 모달용 리소스 end

  // const [menuList, setMenuList] = useState([]);

  const resource = useRecoilValue(getMenuList);
  const { menuList, message } = resource;

  const MenuItem = (props) => {
    const { children, section } = props;
    const { heading, menuId } = section;

    let resultComponent;
    let iconImage;
    let iconImageComponent;

    /*
          200000	운영관리
          300000	대시보드
          400000	서비스관리
          500000	고객지원
          ??????	AI분석예측  /static/images/service_prediction_icon_inactive.png
          800000	모바일
    */
    switch (menuId) {
      case '410000': // 자원관리
        iconImage = '/static/images/dashboard_icon_inactive.png';
        break;
      case '200000': // 운영관리
        iconImage = '/static/images/dashboard_icon_inactive.png';
        break;
      case '300000': // 대시보드
        iconImage = '/static/images/service_management_icon_inactive.png';
        break;
      case '400000': // 서비스관리
        iconImage = '/static/images/customer_support_icon_inactive.png';
        break;
      case '500000': // 고객지원
        iconImage = '/static/images/operation_management_icon_inactive.png';
        break;
      case '800000': // 모바일
        iconImageComponent = <SmartphoneIcon sx={{ color: '#fff' }} />;
        // iconImage = "/static/images/service_prediction_icon_inactive.png"
        break;
      default:
        break;
    }

    resultComponent = (
      <Box
        sx={{
 
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {iconImage == null ? (
          iconImageComponent
        ) : (
          <img
            src={iconImage}
            width={24}
            height={24}
            alt="메뉴이미지"
            // style={{ marginTop: 30, marginBottom: 30 }}
          />
        )}
        <ListSubheader sx={{ color: '#FFFFFF' }} component="div" disableSticky>
          {heading}
        </ListSubheader>
      </Box>
    );

    return resultComponent;
  };

  const MenuModal = (props) => {
    const {
      selectedIdx,
      open,
      title,
      menuType,
      menuItems,
      menuModalCloser,
      menuIndex
    } = props;
    const navigate = useNavigate();

    let positionTop = 150;
    let indexHeight = menuIndex * 80;
    positionTop += indexHeight;

    const MenuModalWrapper = styled(Box)(
      ({ theme }) => `
        display: ${open ? 'block' : 'none'};
        top: 0;
        left: ${theme.sidebar.width};
        position: fixed;
        width: 100%;
        height: 100%;
      `
    );

    const Modal = styled(Box)`
      display: flex;
      
      top: ${positionTop}px;
      position: fixed;
      // position: absolute;
      width: 261px;
      background: #ffffff;
      border-radius: 6px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      left: 90px;
      // top: 80px;
      padding-bottom: 30px;
      
      z-index: 10;
      flex-direction: column;

      // transform: none;
      // transition: opacity 253ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 168ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      // transform-origin: -16px 143px;
    `;

    const TitleText = styled(Typography)`
      font-weight: 700;
      font-size: 18px;
      color: #121212;
      font-family: 'SpoqaHanSansNeo';
      margin: 30px 20px;
    `;

    const MenuItem = styled(Box)`
      // font-weight: 700;
      // font-size: 18px;
      color: ${themeName === "NebulaFighterTheme" ? "#ffffff" : "#121212"};
      font-family: 'SpoqaHanSansNeo';
      padding: 5px 30px;
      // text-decoration: underline;
      text-decoration: unset;
      text-decoration-color: rgba(85, 105, 255, 0.4);
      cursor: pointer;
      // border-left: 3px solid #1E4DBA;

      &:hover {
        background-color:  ${themeName === "NebulaFighterTheme" ? "#CCCCCC" : "#1E4DBA"};
        color: ${themeName === "NebulaFighterTheme" ? "black" : "#ffffff"}; 
      }
    `;
    const customLink = (menuType, item) => {
      if (menuType === '410000') {
        window.location.replace(item.link);
      } else {
        navigate(item.link);
      }
    };
    return (
      <MenuModalWrapper onClick={menuModalCloser}>
        <Modal onMouseLeave={menuModalCloser} style={{backgroundColor: themeName === "NebulaFighterTheme" ? "#003333" : "none"}}>
          <TitleText style={{color: themeName === "NebulaFighterTheme" ? "white" : "black"}}>{title}</TitleText>

          {menuItems.map((item, index) => {
            const { link, name } = item;
            return (
              <MenuItem
                onClick={() => customLink(menuType, item)}
                key={'menuModalItem_' + index}
              >
                {name}
              </MenuItem>
            );
          })}
        </Modal>
      </MenuModalWrapper>
    );
  };

  const menuModalCloser = () => {
    if (menuModalOpen) {
      setMenuModalType('');
      setMenuModalItems([]);
      setMenuModalOpen(false);
      setMenuModalPositionTop(null);
      setMenuModalHeading('');
    }
  };

  useEffect(() => {
    // menuListRequest();
  }, []);

  return (
    <>
      <Box sx={{ marginTop: '30px' }} >
        {menuList.map((section, index) => (
          <MenuWrapper
            
            key={'menuItem_' + index}
            onMouseOver={(e) => {
              setMenuIndex(index);
              setMenuModalPositionTop(e.clientY);
              setMenuModalType(section.menuId);
              setMenuModalItems(section.items);
              setMenuModalHeading(section.heading);
              setMenuModalOpen(true);
            }}
            onClick={(e) => {
              setMenuModalPositionTop(e.clientY);
              setMenuModalType(section.menuId);
              setMenuModalItems(section.items);
              setMenuModalHeading(section.heading);
              setMenuModalOpen(true);
            }}
            // subheader={
            //   <MenuItem section={section}/>
            // }
          >
            <MenuItem  section={section} />
          </MenuWrapper>
        ))}
      </Box>

      <MenuModal
        
        menuIndex={menuIndex}
        open={menuModalOpen}
        menuType={menuModalType}
        title={menuModalHeading}
        menuItems={menuModalItems}
        positionTop={menuModalPositionTop}
        menuModalCloser={menuModalCloser}
      />
    </>
  );
}

export default SidebarMenu;
