const currentDate: {
  startDt: string;
  endDt: string;
} = {
  startDt: 'YYYY-MM-DD 00:00:00',
  endDt: 'YYYY-MM-DD 23:59:59'
};

const customerDefaultSearch = {
  id: '',
  custNm: '',
  custId: '',
  bjdongCd: '',
  addr: '',
  addrDetail: '',
  mobileNo: '',
  pptSeq: '',
  bizId: '',
  bizNm: ''
};

const MINIMUM_WIDTH_SIZE = 900;
const SEARCH_BTN_SMALL_WIDTH = 50;

const LOCAL_URL: string = 'http://localhost:3000';
const DEV_URL: string = 'https://winergy.kr';

const CURRENT_URL: string = DEV_URL;

const hideZeroValue = (searchType, arr) => {
  let now = new Date();
  if (searchType === 'HOUR') {
    return arr.slice(0, now.getHours() + 1);
  } else {
    return arr.slice(0, now.getDate());
  }
};

const TOKEN_NM: {
  ACCESS: string;
  REFRESH: string;
} = {
  ACCESS: 'jwt',
  REFRESH: 'remember'
};

const SIDEBAR_TYPE: {
  BIG: string;
  SMALL: string;
} = {
  BIG: 'BIG',
  SMALL: 'SMALL'
};

const getImg = (pty, sky) => {
  let imgUrl = '';
  switch (pty) {
    case 0: // 강수 형태 없음
      if (sky === 1) {
        // 맑음
        imgUrl = '/static/images/ic_sun_s.png';
      } else if (sky === 3) {
        // 구름 많음
        imgUrl = '/static/images/ic_cloudy_s.png';
      } else {
        // 흐림
        imgUrl = '/static/images/ic_brokenclouds_s.png';
      }
      break;
    case 1: // 비
      imgUrl = '/static/images/ic_rainy_s.png';
      break;
    case 2: // 비/눈
      imgUrl = '/static/images/ic_showerrain_s.png';
      break;
    case 3: // 눈
      imgUrl = '/static/images/ic_snow_s.png';
      break;
    case 4: // 소나기
      imgUrl = '/static/images/ic_mist_s.png';
      break;
    default: // 임시로 그냥 태양 이미지 설정해놈...
      imgUrl = '/static/images/ic_sun_s.png';
      break;
  }
  return imgUrl;
};
const API_URL = {
  REPORT_URL: '/report'
};

const logoImage = (logoType,sidebarType) => {
  let logoPath = "/static/images/logo_white_icon.png" 
  if (logoType === "20220001") { 
    if (sidebarType === SIDEBAR_TYPE.SMALL) {
      logoPath = "/static/images/sunmorning_small_logo.png"
    } else {
      logoPath = "/static/images/sunmorning_new_logo.png"
    }
  } else if (logoType === "20210001") {
    if (sidebarType === SIDEBAR_TYPE.SMALL) { 
      logoPath = "/static/images/logo_icon.png" 
    } else {
      logoPath = "/static/images/logo_white_icon.png"
    }
  }
  return logoPath;
}

const IMEI_TYPE : {
  DEV: string;
  IMEI: string;
} = {
  DEV: 'devEui',
  IMEI: 'imei'
}

export {
  currentDate,
  CURRENT_URL,
  customerDefaultSearch,
  MINIMUM_WIDTH_SIZE,
  SEARCH_BTN_SMALL_WIDTH,
  hideZeroValue,
  TOKEN_NM,
  getImg,
  LOCAL_URL,
  API_URL,
  IMEI_TYPE,
  logoImage,
  SIDEBAR_TYPE
};

