// import axios from 'axios';
import axios from 'axios';
import { createContext, useContext, useRef, useState } from 'react';
import { inqReplyListApi } from 'src/api/InqApi';
import {
  customerDefaultSearch,
  TOKEN_NM
} from 'src/content/pages/Components/CommonData/CommonData';
// import { useSetRecoilState } from 'recoil';
// import { dashboardGridKeyState } from 'src/recoil/atoms';

export const AuthenticationContext = createContext(undefined);

/*const useMergeState = function (initialState) {
  const [state, setState] = useState(initialState);
  const setMergedState = setMergedStateCallBack =>
    // setState(prevState => Object.assign({}, prevState, newState)
    setState(prevState => 
      setMergedStateCallBack(prevState, (newState)=>
        Object.assign({}, prevState, newState)
      )
    );
  return [state, setMergedState];
}*/

export const AuthenticationProvider = ({ children }) => {
  // const principalData = JSON.parse( localStorage.getItem(`principal`) );
  // const authenticationData = JSON.parse( localStorage.getItem(`authentication`) );
  const [themeName, _setThemeName] = useState(localStorage.getItem('appTheme') === null ? "PureLightTheme": localStorage.getItem('appTheme'))
  const principalPrototype = {
    userNm: null,
    userAuthTypeNm: null,
    userAuthTypeCd: null,
    dsSeq: null,
    userId: null,
    bizId : null,
    logoType: null,
  };
  const [principal, setPrincipal] = useState(principalPrototype);
  const [replyList, setReplyList] = useState([]);
  // const [authentication, setAuthentication] = useState(principal.userId != null ? true : false);
  const authentication = useRef(principal.userId != null ? {loginType:true , loginPath : "MON", logoType: principal.logoType } : {loginType:false , loginPath : null, loginBiz: null});
  // const [principal, setPrincipal ] = useState(JSON.parse( localStorage.getItem(`principal`) ));

  const tokenCheckUserInfo = async () => {
    try {
      const data = await axios.post('/mobile/common/tokenCheckUserInfo');
      if (data.headers.authorization !== undefined) {
        localStorage.setItem(TOKEN_NM.ACCESS, data.headers.authorization);
      }
      setPrincipal(data.data.data.principal);
      authentication.current = {loginType:true , loginPath : data.data.data.principal.initSvcTypeCd, logoType: data.data.data.principal.logoType };
    } catch (e) {
      console.log(e);
    }
  };

	const saveToken = (tokenType,token) => {
		let params :any = {
			'options':{'key':tokenType, 'val': token}
		}
		params = JSON.stringify(params);
		window.webkit.messageHandlers.ifSetPrefVal.postMessage(params);
  }
  
  const removeToken = (tokenType,token) => {
		let params :any = {
			'options':{'key':tokenType, 'val': token}
		}
		params = JSON.stringify(params);
		window.webkit.messageHandlers.ifSetPrefVal.postMessage(params);
	}

  const login = (data) => {
    var agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf("iphone") > -1 || agent.indexOf("ipad") > -1 || agent.indexOf("ipod") > -1) { 
      if (data.headers.authorizationref !== undefined) {
        saveToken(TOKEN_NM.REFRESH, data.headers.authorizationref)
      }
      saveToken(TOKEN_NM.ACCESS, data.headers.authorization)
    } else {
      if (data.headers.authorizationref !== undefined) {
        localStorage.setItem(TOKEN_NM.REFRESH, data.headers.authorizationref);
      }
      localStorage.setItem(TOKEN_NM.ACCESS, data.headers.authorization);
    }

    setPrincipal(data.data.data.principal);
    authentication.current = {loginType:true , loginPath : data.data.data.principal.initSvcTypeCd, logoType: data.data.data.principal.logoType };
  };

  const logout = () => {
    var agent = navigator.userAgent.toLowerCase();
    authentication.current = { loginType: false, loginPath: null, logoType: principal.logoType };
    setPrincipal(principalPrototype);
    if (agent.indexOf("iphone") > -1 || agent.indexOf("ipad") > -1 || agent.indexOf("ipod") > -1) { 
      removeToken(TOKEN_NM.ACCESS, null);
      removeToken(TOKEN_NM.REFRESH, null);
    } else {
      localStorage.removeItem(TOKEN_NM.ACCESS);
      localStorage.removeItem(TOKEN_NM.REFRESH);
    }
  };

  const getPrincipal = () => principal;

  const getDashboard = () => localStorage.getItem(`dsSeq`);

  const setDashboard = ({ dsSeq }) => {
    localStorage.removeItem(`dsSeq`);
    localStorage.setItem(`dsSeq`, dsSeq);
  };

  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('error');
  const alertControl = (alertMessage) => {
    setAlert(true);
    setAlertMessage(alertMessage);
  };
  const [customerInfo, setCustomerInfo] = useState(customerDefaultSearch);
  const resetCustomerInfo = () => {
    setCustomerInfo(customerDefaultSearch);
  };

  const [searchOpen, setSearchOpen] = useState({
    open: true,
    title: '검색창 닫기'
  });

  const getReplyList = async (bdSeq) => {
    try {
        const replyListItem = await inqReplyListApi(bdSeq);
        
        setReplyList(replyListItem.data)
    } catch (e) {
        alertControl(e.message)
    }
}

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });
  return (
    <AuthenticationContext.Provider
      value={{
        authentication,
        login,
        logout,
        principal,
        setPrincipal,
        getPrincipal,
        setDashboard,
        getDashboard,
        alert,
        setAlert,
        alertMessage,
        setAlertMessage,
        alertControl,
        customerInfo,
        setCustomerInfo,
        resetCustomerInfo,
        searchOpen,
        setSearchOpen,
        windowSize,
        setWindowSize,
        tokenCheckUserInfo,
        themeName,
        _setThemeName,
        replyList,
        setReplyList,
        getReplyList
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthenticationContext);
};
