import { createRoot } from 'react-dom/client';
import App from './App';

// import 'src/utils/chart';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import 'nprogress/nprogress.css';
import 'src/i18n/locales/i18n'; // 추가
import { SidebarProvider } from './contexts/SidebarContext';

import { RecoilRoot } from 'recoil';
import { AuthenticationProvider } from './contexts/AuthenticationContext';
import { NaverMapInitProvider } from './contexts/NaverMapInitContext';

import CustomAlertDialog from './components/AlertDialog/CustomAlertDialog';

createRoot(document.getElementById('root')).render(
    // <React.StrictMode>
    <HelmetProvider>
        <SidebarProvider>
            <NaverMapInitProvider>
                <RecoilRoot>
                    <AuthenticationProvider>
                        <BrowserRouter>
                            {/* alert 창 */}
                            {/* 
                                기존 방식 ->
                                <CommonDialog open={alertOpen} onClose={handleAlertClose} content={alertCont} />
                                사용해서 원하는 곳마다 다음 컴포넌트 추가후 alertOpen, handleAlertClose, alertCont 생성해줘야하는 불편함이 있음.
                                ------------------------------------------------------------------------------------------------------------------
                                CustomAlertDialog로 대체 시->
                                alert창을 프로젝트 최상단에 위치하게 하여 
                                AuthenticationContext.tsx -> alertControl() 함수로 간단하게 alert창을 띄울수 있음.
                            */}
                            <CustomAlertDialog />
                            {/* alert 창 */}
                            <App />
                        </BrowserRouter>
                    </AuthenticationProvider>
                </RecoilRoot>
            </NaverMapInitProvider>
        </SidebarProvider>
    </HelmetProvider>
    // </React.StrictMode>
);

serviceWorker.unregister();
