import { useEffect} from 'react';

import {

	CircularProgress,
  Box,
  styled,
} from "@mui/material";



function Loader() {

  const LoaderWrapper = styled(Box)(
		({ theme }) => `
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.2);
	`
	);

  useEffect(() => {
    return ()=>{}
  },[]);

  return (
    <LoaderWrapper>
      <CircularProgress size={64} disableShrink thickness={3} />
    </LoaderWrapper>

  ); 
}

export default Loader;
 