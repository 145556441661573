import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled, TextField } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import CommonDialog from "src/components/AlertDialog";
import { ItemGroup, PopupFormControlLabel } from "src/components/Common/ItemGroup";
import { useAuth } from '../../../../contexts/AuthenticationContext';
import Loader from 'src/content/dashboards/Dashboard/suspenseLoader/Loader';

const StyledButton = styled(Button)(({ theme }) => ({
	background: 'red',
    color: '#fff',
    borderRadius: '4px',
    width: '80px'
}));

export default function PwdCheck(props) {
	const { onClose, open } = props;

	//사용자 정보
	const auth = useAuth();

	// 알럿
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertCont, setAlertCont] = useState('');

	const [ pwdCheckParam, setPwdCheckParam ] = useState({userId: '', userPwd: ''});
	const [ paramError, setParamError ] = useState({userId: '', userPwd: ''});

	const [loader, setLoader] = useState(false);

	const handlePwdCheckClose = () => {
		onClose(false);
	};

	const handleAlertClose = () => {
		setAlertOpen(false);
	};

	const handleChange = name => (event: React.ChangeEvent<HTMLInputElement>) => {
		setPwdCheckParam({...pwdCheckParam, [name] : event.target.value});
		setParamError({...paramError, [name] : ''});
	};

	const handlePwdCheck = () => {
		setLoader(true);
		const params = new URLSearchParams();

		params.append('userId', pwdCheckParam.userId);
		params.append('userPwd', pwdCheckParam.userPwd);
		axios.post("/pwdCheck",
			params
		).then(function(response) {
			const data = response.data;

			if(data.result === "SUCCESS") {
				// 팝업 닫기 후 변경 데이터 셋팅
				onClose(true);
			} else {
				setAlertCont(data.message);
				setAlertOpen(true);
			}
			setLoader(false);
		}).catch(function(error) {
			// 오류시 처리 함수
			console.log(error);
			setLoader(false);
		});
	};

	const loginUserInfoRequest = () => {
		// console.log(auth.getPrincipal().userId)
		axios.post("/loginUserInfo")
		.then(function(response) {
			const user = response.data.data.loginUserInfo[0];
			// 사용자 정보 셋팅
			setPwdCheckParam({userId: user.userId, userPwd: ''});
			setParamError({userId: '', userPwd: ''});
		}).catch(function(error) {
			// 오류시 처리 함수
			console.log(error);
		})
	}

	useEffect(() => {
		if(open) {
			// 사용자 정보 조회
			// loginUserInfoRequest();
			setPwdCheckParam({userId: auth.getPrincipal().userId, userPwd: ''});
		}else{
			//초기화
			setPwdCheckParam({userId: '', userPwd: ''});
		}
	}, [open]);

	const POPUP_WIDTH:string = '600px';
	return (
		<Dialog open={open} maxWidth='xl' onClose={handlePwdCheckClose} >
			<DialogTitle sx={{
				color: '${theme.palette.text.primary}',
				fontFamily: 'Spoqa Han Sans Neo',
				fontStyle: 'normal',
				fontWeight: 700,
				fontSize: '20px', 
			}}>비밀번호 확인</DialogTitle>
			<IconButton aria-label="close" onClick={handlePwdCheckClose} sx={{ position: 'absolute', right: 8, top: 7, color: (theme) => theme.palette.grey[500] }}>
				<GridCloseIcon/>
			</IconButton>
			<DialogContent dividers sx={{flexGrow:1, maxWidth: POPUP_WIDTH}}>
				{loader ? <Loader/> : null}
				<ItemGroup>
					<PopupFormControlLabel control={
						<TextField
							size="small" fullWidth disabled
							inputProps={{maxLength: 20}}
							value={pwdCheckParam.userId}
							onChange={handleChange("userId")}
						/>
					} label="아이디*" labelPlacement="start"/>
					<PopupFormControlLabel control={
						<TextField
							size="small" type="password"  fullWidth
							value={pwdCheckParam.userPwd}
							inputProps={{maxLength: 16}}
							error={paramError.userPwd === '' ? false : true}
							helperText={paramError.userPwd}
							onChange={handleChange("userPwd")}
							onKeyPress={(event) => {
								if(event.key === 'Enter') {
									handlePwdCheck();
								}
							}}
						/>
					} label="비밀번호*" labelPlacement="start"/>
				</ItemGroup>
			</DialogContent>
			<DialogActions>
				<Box sx={{flexGrow:1, width:'100%', display: 'flex', justifyContent: 'center'}}>				
					<StyledButton sx={{background: '#898989'}} onClick={handlePwdCheckClose}>닫기</StyledButton>
					<Box sx={{width:'8px'}}/>
					<StyledButton sx={{background: '#1E4DBA'}} onClick={handlePwdCheck}>확인</StyledButton>
				</Box>
			</DialogActions>
			<CommonDialog open={alertOpen} onClose={handleAlertClose} content={alertCont}/>
		</Dialog>
	)
}