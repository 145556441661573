import axios from 'axios';

const inqGetListApi = async (currentNum,bdKindCd, bdStatusCd, bdTitle, custId, custNm, regStDt, regEndDt, updStDt, updEndDt, bizId) => {
    const bodyObj: any = {
        currentNum,
        bdKindCd: bdKindCd,
        bdStatusCd: bdStatusCd,
        bdTitle: bdTitle,
        custId: custId,
        custNm: custNm,
        bizId: bizId
    };

    if (regStDt != null && regEndDt != null) {
        bodyObj.regStDt = regStDt;
        bodyObj.regEndDt = regEndDt;
    }

    if (updStDt != null && updEndDt != null) {
        bodyObj.updStDt = updStDt;
        bodyObj.updEndDt = updEndDt;
    }

    const data = await axios.post('/inquireList', bodyObj);
    return data;
};

const inqGetFileInfoApi = async (bdSeq) => {
    const data = await axios.post('/ingFileInfo', { bdSeq: bdSeq });
    return data.data.data.fileInfo;
};

const inqInsertApi = async (regId,bdTitle,bdConts,bdKindCd,file,bizId) => {
    const params = new FormData(); 

    params.append('custId', regId);
    params.append('bdTitle', bdTitle);
    params.append('bdConts', bdConts);
    params.append('bdKindCd', bdKindCd);
    params.append('file', file);
    params.append('bizId', bizId);

    const data = await axios.post('/insertInqList', params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    return data;
}

const inqUpdateApi = async (bdSeq,bdKindCd,bdTitle,bdConts) => {
    const bodyObj = {
        bdSeq,
        bdKindCd,
        bdTitle,
        bdConts
        }
    const data = axios.post('/updateInqStat', bodyObj)
    return data;
}

const inqReplyListApi = async (bdSeq) => {
    const bodyObj = {
        bdSeq
    }
    const data = await axios.post('/getBdReplyList', bodyObj);
    return data;
}

const inqReplyInsertApi = async (bdSeq,bdReplyConts,bdReplyAddInfos,file,bdStatusCd) => {
    const params = new FormData();
    params.append('bdSeq', bdSeq);
    params.append('bdReplyConts', bdReplyConts);
    params.append('bdReplyAddInfos', bdReplyAddInfos);
    params.append('file', file);
    params.append('bdStatusCd', bdStatusCd);

    const bodyObj = {
        bdSeq,
        bdReplyConts,
        bdReplyAddInfos,
        bdStatusCd
    };
    
    params.append('bodyObj', new Blob([JSON.stringify(bodyObj)], { type: 'application/json' }));
    
    const data = axios.post('/insertInqReplyList', params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      
    return data;
}

export { inqGetListApi, inqGetFileInfoApi, inqInsertApi, inqUpdateApi, inqReplyListApi, inqReplyInsertApi };

