/**
 * 법정동 종류 코드
 */
export const bjdongType = {
    
    SD: 'SD',
    SGG: 'SGG',
    UMD: 'UMD',
    RI: 'RI',

} as const;

/**
 * 대시보드에서 지역 전체용 value 값
 */
export const DASHBOARD_BJDONG_CD_ALL:string = "0000000000";

/**
 * 이벤트 레벨
 */
export const EventLvl = {

    CRI: 5,
    MAJ: 4,
    MIN: 3,
    WAR: 2,
    NOR: 1,
} as const;
