import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import MaskingClear from 'src/content/pages/Components/Common/MaskingClear';

const StyledButton = styled(Button)`
	// width: 82px;
	// height: 32px;
	border: 1px solid #1e4dba;
	border-radius: 32px;
	// margin-right: 15px;

	// font-weight: 500;
	// font-size: 12px;
	color: #1e4dba;
	font-family: 'SpoqaHanSansNeo';
	font-size: 12px;
	font-weight: 600;
	text-align: center;
	width: auto;
	// width: 92px;
	// height: 34px;
	// padding: 8px 12px;
	// padding: 6px 8px;
	padding: 4px 8px;
`;

type MyGlobalFunctionType = () => void

interface Window {
	myGlobalFunction: MyGlobalFunctionType
}

declare const myGlobalFunction: MyGlobalFunctionType;

function HeaderMasking() {

	const [maskingClearOpen, setMaskingClearOpen] = useState(false);

	const maskingClearClose = (): void => {
		setMaskingClearOpen(false);
		
		setTimeout(() => {
			myGlobalFunction();
		}, 100);
	};

	const handleMaskingPopOpen = () => {
		setMaskingClearOpen(true);
	}


	return (
		<>
			<StyledButton onClick={handleMaskingPopOpen}>
				마스킹 해제
			</StyledButton>
			<MaskingClear open={maskingClearOpen} onClose={maskingClearClose} />
		</>
	);
}

export default HeaderMasking;
