import { useRef, useState } from 'react';

import {
  Box,
  Button,
  lighten,
  Typography
} from '@mui/material';

import PasswordIcon from '@mui/icons-material/Password';
// import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import UserProfile from 'src/content/pages/Components/Users/UserProfile';
import MaskingClear from 'src/content/pages/Components/Common/MaskingClear';
import { useTrans } from 'src/i18n/locales/useTrans';
import CommonDialog from 'src/components/AlertDialog';
import PwdCheck from 'src/content/pages/Components/Common/PwdCheck';
import { useAuth } from 'src/contexts/AuthenticationContext';
import { useNavigate } from 'react-router';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        // padding-left: ${theme.spacing(1)};
		display: flex;
		flex-direction: row;
		// align-items: center;
		align-items: baseline;
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
		margin-right: 4px;
		font-size: 14px;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)};
		font-size: 12px;
`
);

function HeaderUserbox() {

	const ref = useRef<any>(null);
	const [isOpen, setOpen] = useState<boolean>(false);
	const [userProfileOpen, setUserProfileOpen] = useState(false);
	const [maskingClearOpen, setMaskingClearOpen] = useState(false);
	const [pwdCheckOpen, setPwdCheckOpen] = useState(false);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertCont, setAlertCont] = useState('');
	const $ = useTrans();

    ////////////////////로그인 인증 관련
    const navigate = useNavigate();
    const auth = useAuth();
    const principal = auth.getPrincipal();
	const { userNm, userAuthTypeNm, userAuthTypeCd } = principal;
    ////////////////////로그인 인증 관련 end

	const handleOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const userProfileClose = (chgYn): void => {
		setUserProfileOpen(false);
		if(chgYn) {
			setAlertCont($('update'));
			setAlertOpen(true);
		}
	};

	const pwdCheckClose = (checkYn): void => {
		setPwdCheckOpen(false);
		
		if(checkYn) {
			setUserProfileOpen(true);
		}
	};

	const handleUserProfileOpen = () => {
		console.log("testing");

		// 비밀번호 재검사 실시
		setPwdCheckOpen(true);
	}

	const handleAlertClose = () => {
		setAlertOpen(false);
	}

	return (
		<Box sx={{ mr: 1 }}>
			<UserBoxButton color="secondary" ref={ref} onClick={handleUserProfileOpen}>
				
				<UserBoxText>
					<UserBoxLabel variant="body1">
						{userNm}
					</UserBoxLabel>
					<UserBoxDescription variant="body2">
						{userAuthTypeNm}
					</UserBoxDescription>
				</UserBoxText>
				
				{/* <Avatar variant="rounded" alt={user.name} src={user.avatar} /> */}
				{/* <Hidden mdDown>
					<UserBoxText>
						<UserBoxLabel variant="body1">
							{user.name}
						</UserBoxLabel>
						<UserBoxDescription variant="body2">
							{user.jobtitle}
						</UserBoxDescription>
					</UserBoxText>
				</Hidden> */}
				{/* <Hidden smDown>
					<ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
				</Hidden> */}
			</UserBoxButton>
			{/* <Popover
				anchorEl={ref.current}
				onClose={handleClose}
				open={isOpen}
				anchorOrigin={{
				vertical: 'top',
				horizontal: 'right'
				}}
				transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
				}}
			>
				<MenuUserBox sx={{ minWidth: 210 }} display="flex">
					<Avatar variant="rounded" alt={user.name} src={user.avatar} />
					<UserBoxText>
						<UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
						<UserBoxDescription variant="body2">
							{user.jobtitle}
						</UserBoxDescription>
					</UserBoxText>
				</MenuUserBox>
				<Divider sx={{ mb: 0 }} />
				<List sx={{ p: 1 }} component="nav">
					<ListItem button onClick={handleUserProfileOpen}>
						<AccountBoxTwoToneIcon fontSize="small" />
						<ListItemText primary="내정보 수정" />
					</ListItem>
					<ListItem button onClick={handleMaskingPopOpen}
					>
						<PasswordIcon fontSize="small" />
						<ListItemText primary="마스킹 해제" />
					</ListItem>
					<ListItem
						button
						to="/management/profile/settings"
						component={NavLink}
					>
						<AccountTreeTwoToneIcon fontSize="small" />
						<ListItemText primary="Account Settings" />
					</ListItem>
				</List>
				<UserProfile open={userProfileOpen} onClose={userProfileClose}/>
				<PwdCheck open={pwdCheckOpen} onClose={pwdCheckClose}/>
				<CommonDialog open={alertOpen} onClose={handleAlertClose} content={alertCont}/>
			</Popover> */}
			<PwdCheck open={pwdCheckOpen} onClose={pwdCheckClose}/>
			<UserProfile open={userProfileOpen} onClose={userProfileClose}/>
			<CommonDialog open={alertOpen} onClose={handleAlertClose} content={alertCont}/>
		</Box>
	);
}

export default HeaderUserbox;
