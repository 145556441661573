import { atom } from "recoil";

// 마스킹 10분 제한
export const maskingTimerState = atom<number>({
	key: "maskingTimerState",
	default: 600, // 10분
});

// 마스킹 여부
export const maskingState = atom<String>({
	key: 'maskingState',
	default: 'N'
});