import { Box, Button, Modal, styled, Typography } from "@mui/material";
import PropTypes from 'prop-types';

const StyledButton = styled(Button)`
	height: 48px;
	background: #5c80ec;
	font-size: 16px;
	font-weight: 500;
	color: #ffffff;
	font-family: 'SpoqaHanSansNeo';
`;

const ModalTitle = styled(Typography)`
	font-weight: 700;
	font-size: 20px;
	text-align: center;
	color: #3956ae;
	margin-top: 52px;
	font-family: 'SpoqaHanSansNeo';
`;

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 320,
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: 1,
};

const ModalDescription = styled(Typography)`
	font-weight: 600;
	font-size: 14px;
	text-align: center;
	color: #3b3b3b;
	line-height: 24px;
	margin: 16px 0 45px;
	font-family: 'SpoqaHanSansNeo';
`;

function CommonDialog(props) {
	return (
		<Modal open={props.open} onClose={props.onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
			<Box sx={style}>
			<ModalTitle>{props.title}</ModalTitle>
			<ModalDescription>{props.content}</ModalDescription>
			<StyledButton variant="contained" fullWidth onClick={props.onClose} sx={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
				확인
			</StyledButton>
			</Box>
		</Modal>
	)
}

CommonDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string,
	content: PropTypes.string.isRequired,
};

export default CommonDialog;