import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import HiveOutlinedIcon from '@mui/icons-material/HiveOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import { TreeItem, treeItemClasses, TreeView } from '@mui/lab';
import { IconButton, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { logoImage, SIDEBAR_TYPE } from 'src/content/pages/Components/CommonData/CommonData';
import { useAuth } from 'src/contexts/AuthenticationContext';
import { getMenuList } from 'src/recoil/selectors';

export default function SmalSidebar(props:any) {
  type Anchor = 'top' | 'left' | 'bottom' | 'right';
  const navigate = useNavigate();
  const resource = useRecoilValue(getMenuList);
  const { menuList } = resource;
  const { principal } = useAuth()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

    const labelIconList = [ViewComfyOutlinedIcon,LanOutlinedIcon,ChatOutlinedIcon,HiveOutlinedIcon,SettingsOutlinedIcon]
  
    const toggleDrawer = (anchor, open,url=null) => (event) => {
      setState({ ...state, [anchor]: open });
      if(url!=null) navigate(url)
    };
    
    // ------------------------ MUI TREE START ------------------------
    // |                                                              |
      const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
        color: theme.palette.text.secondary,
        [`& .${treeItemClasses.content}`]: {
          color: theme.palette.text.secondary,
          borderTopRightRadius: theme.spacing(2),
          borderBottomRightRadius: theme.spacing(2),
          paddingRight: theme.spacing(1),
          fontWeight: theme.typography.fontWeightMedium,
          '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
          },
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
          },
          [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
          },
        },
        [`& .${treeItemClasses.group}`]: {
          marginLeft: 0,
          [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
          },
        },
      }));
      
      function StyledTreeItem(props: any) {
        const {
          bgColor,
          color,
          labelIcon: LabelIcon,
          labelInfo,
          labelText,
          ...other
        } = props;
      
        return (
          <StyledTreeItemRoot
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                  {labelText}
                </Typography>
                <Typography variant="caption" color="inherit">
                  {labelInfo}
                </Typography>
              </Box>
            }
            style={{
              '--tree-view-color': color,
              '--tree-view-bg-color': bgColor,
            }}
            {...other}
          />
        );
      }
    // |                                                              |
    // ------------------------ MUI TREE END ------------------------
    
    // ------------------------ MUI DRAW START ------------------------
    // |                                                              |
   
    const list = (anchor: Anchor) => (
    
    <Box style={{height:"100%"}}
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
    //   onClick={toggleDrawer(anchor, false)}
    //   onKeyDown={toggleDrawer(anchor, false)}
        >
    <List style={{textAlign:"center" }}>
        <img 
            src={logoImage(principal.logoType,SIDEBAR_TYPE.SMALL)}
            alt="logo"
            style={{cursor:"pointer",objectFit: "contain",width:"100px"}}
            onClick={()=>navigate("/")}
          />
    </List>
    <Divider />
      <List>
      <TreeView
      aria-label="gmail"
      defaultExpanded={['200000','300000','400000','500000',"410000"]}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ height: "100%", flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                >
      
      {menuList.map((item,key) =>
          <>
              <StyledTreeItem nodeId={item.menuId} labelText={item.heading} labelIcon={labelIconList[key]} >
              {item.items.map((val,index) =>
                    <>
                      <StyledTreeItem nodeId={val.link} labelText={val.name} onClick={toggleDrawer(anchor, false, val.link)} labelIcon={ChevronRightOutlinedIcon} />
                    </>
                )}
              </StyledTreeItem>
          </>
      )}
    </TreeView>
      </List> 
    </Box>
    );
   // |                                                            |
   // ------------------------ MUI DRAW END ------------------------
    
    
  return (
    <div>
      {(['left'] as const).map((anchor) => (
        <React.Fragment key={anchor}>  
            <IconButton onClick={toggleDrawer("left", true)}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
         
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}