import { useContext } from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MINIMUM_WIDTH_SIZE } from 'src/content/pages/Components/CommonData/CommonData';
import { SidebarContext } from 'src/contexts/SidebarContext';
import SmalSidebar from '../Sidebar/SmalSidebar';
import HeaderButtons from './Buttons';
import HeaderLogout from './Buttons/Logout';
import HeaderNotifications from './Buttons/Notifications';
import HeaderUserbox from './Userbox';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        // padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        // justify-content: space-between;
        justify-content: flex-end;
        width: 100%;
        // @media (min-width: ${theme.breakpoints.values.lg}px) {
        //     left: ${theme.sidebar.width};
        //     width: auto;
        // }
`
);

function Header(props) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <HeaderWrapper display="flex" alignItems="center">
      {/* <Box display="flex" alignItems="center">
        <Hidden lgUp>
          <Logo />
        </Hidden>
        <Hidden mdDown>
          <HeaderMenu />
        </Hidden>
      </Box> */}
      {props.windowWidth <= MINIMUM_WIDTH_SIZE ?
        <div style={{marginRight: "auto", paddingLeft: "10px"}}> 
           <SmalSidebar/>
        </div>
        : null }
     
      <Box sx={{ height: '100%' }} display="flex" alignItems="center">
        <HeaderButtons />
        
        <HeaderUserbox />
        <HeaderNotifications/>
       
        <HeaderLogout/>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
