import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import tranEn from './Files/en.json';
import tranKo from './Files/ko.json';

const resources = {
  en: { translation: tranEn },
  ko: { translation: tranKo },
}

// const userLanguage = window.navigator.language || window.navigator.userLanguage;
const userLanguage = window.navigator.language || 'ko';

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || userLanguage || 'ko',
  fallbackLng: 'ko',
  keySeparator: false,
  interpolation: {
    escapeValue: true,
	prefix: '{',
	suffix: '}',
  }
})

export default i18n;