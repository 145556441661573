import { FC, ReactNode, Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Status500 from 'src/content/pages/Status/Status500';
import Fallback from './Fallback';

// interface CriticalErrorBoundaryProps {
//   children?: ReactNode;
// }

// const CriticalErrorBoundary: FC<CriticalErrorBoundaryProps> = ({ children }) => {
const CriticalErrorBoundary = ({ children }) => {

  const onError = (error:any) => {
    const {response} = error;
    const {status, data} = response;

    if( status == 401 || status == 403 ){
      throw error;
    }
  }

  return (
    <ErrorBoundary
      onError={onError}
      FallbackComponent={Fallback}
      // onReset={() => {
      //   // reset the state of your app so the error doesn't happen again
      // }}
    >
      {children}
    </ErrorBoundary>
  );


};

// CriticalErrorBoundary.propTypes = {
//   // children: PropTypes.node
// };

export default CriticalErrorBoundary;
