import Status401 from "./Status401";
import Status404 from "./Status404";
import PropTypes from 'prop-types';
import Status500 from "./Status500";


function Status(props) {

  const {status, data} = props;

  const checkStatus = (status)=>{

    let resultComponent = null

    switch (status) {  
      case 401:  //인증 정보 만료
        resultComponent = <Status401 data={data}/>
        break;

      case 404: //리소스 없음
        resultComponent = <Status404/>
        break;

      case 500: // 서버 에러
        resultComponent = <Status500/>
        break;

      default:
        //알수 없는 에러 페이지
        resultComponent = <Status500/>
        break;
    }

    return resultComponent;
  }

  return (
    <>
      {checkStatus(status)}
    </>
  );
}

Status.propTypes = {
	status: PropTypes.any.isRequired,
	data: PropTypes.any.isRequired
};

export default Status;
