import { FC, lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
// import { PartialRouteObject } from 'react-router';

import SuspenseLoader from 'src/components/SuspenseLoader';
import BaseLayoutComponent from 'src/layouts/BaseLayout';
import SidebarLayoutComponent from 'src/layouts/SidebarLayout';
import Redirect from './components/Redirect';


import CriticalErrorBoundary from './error/CriticalErrorBoundary';
import RootErrorBoundary from './error/RootErrorBoundary';
import MobileLayout from './layouts/mobileLayout';


// const PageErrorBoundaryLoader  = (Component) => {

//       let FallbackComponent = ({error, resetErrorBoundary})=>(<Status500 />)

//       let ErrorBoundaryWrappingComponent = (props)=>(
//         <ErrorBoundary
//             FallbackComponent={FallbackComponent}
//             onReset={() => {
//               // reset the state of your app so the error doesn't happen again
//             }}
//           >
//             <Component {...props} />
//         </ErrorBoundary>
//       )

//       return <ErrorBoundaryWrappingComponent/>
// }

const PageLoader = 
  (Component): FC =>
  (props) => (
    <RootErrorBoundary>
      <Component {...props} />
    </RootErrorBoundary>
  );

const Loader =
  (Component): FC =>
  (props) => (
    <CriticalErrorBoundary>
      <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
      </Suspense>
    </CriticalErrorBoundary>
  );

// Pages

// const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

// const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));
const Dashboards = Loader(lazy(() => import('src/content/dashboards/Dashboard')));
const PersonalMonitoring = Loader(lazy(() => import('src/content/pages/Components/Monitoring')));
const CollectionMonitoring = Loader(lazy(() => import('src/content/dashboards/CollectionMonitoring')));

// Applications

// const Messenger = Loader(lazy(() => import('src/content/applications/Messenger')));
// const Transactions = Loader(lazy(() => import('src/content/applications/Transactions')));
// const UserProfile = Loader(lazy(() => import('src/content/applications/Users/profile')));
// const UserSettings = Loader(lazy(() => import('src/content/applications/Users/settings')));
// const TestList = Loader(lazy(() => import('src/content/applications/TestList')));

// Components

// const Buttons = Loader(lazy(() => import('src/content/pages/Components/Buttons')));
// const Modals = Loader(lazy(() => import('src/content/pages/Components/Modals')));
// const Accordions = Loader(lazy(() => import('src/content/pages/Components/Accordions')));
// const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
// const Badges = Loader(lazy(() => import('src/content/pages/Components/Badges')));
// const Tooltips = Loader(lazy(() => import('src/content/pages/Components/Tooltips')));
// const Avatars = Loader(lazy(() => import('src/content/pages/Components/Avatars')));
// const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
// const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));
const CodeLists = Loader(lazy(() => import('src/content/pages/Components/CodeLists')));
const NoticeLists = Loader(lazy(() => import('src/content/pages/Components/NoticeLists')));
const UserLists = Loader(lazy(() => import('src/content/pages/Components/Users')));
const InqLists = Loader(lazy(() => import('src/content/pages/Components/Inquiries')));
const PowerPlantLists = Loader(lazy(() => import('src/content/pages/Components/PowerPlantLists')));
const DrLists = Loader(lazy(() => import('src/content/pages/Components/DrLists')));
const PointExchange = Loader(lazy(() => import('src/content/pages/Components/PointExchange')));
const PointHistory = Loader(lazy(() => import('src/content/pages/Components/PointHistory')));
const EnergyReportList = Loader(lazy(() => import('src/content/pages/Components/EnergyReportList/EnergyReportList')));
const StatLists = Loader(lazy(() => import('src/content/pages/Components/StatLists')));
const PowerGenStatLists = Loader(lazy(() => import('src/content/pages/Components/GenStatLists')));
const WorkHistory = Loader(lazy(() => import('src/content/pages/Components/WorkHistory')));
const EquipLists = Loader(lazy(() => import('src/content/pages/Components/EquipLists')));
const RtuLists = Loader(lazy(() => import('src/content/pages/Components/RtuLists')));
const CustomerLists = Loader(lazy(() => import('src/content/pages/Components/CustomerLists')));
const ErrorLogLists = Loader(lazy(() => import('src/content/pages/Components/ErrorLogLists')));
const CollectLogLists = Loader(lazy(() => import('src/content/pages/Components/CollectLogLists')));
const LoginLog = Loader(lazy(() => import('src/content/pages/Components/LoginLog')));
const NoticeLogLists = Loader(lazy(() => import('src/content/pages/Components/NoticeLogLists')));
const ArkStatLists = Loader(lazy(() => import('src/content/pages/Components/ArkStatLists')));

// Components > Mobile
const MobileLists = Loader(lazy(() => import('src/content/pages/Components/Mobiles')));
const MobileHome = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/home')));
const MobileFindId = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/findId')));
const MobileFindPwd = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/findPwd')));
const MobileSetting = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/setting')));
const MobileProfile = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/setting/profile')));
const MobileProfileEdit = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/setting/profile/edit')));
const MobileAddInfo = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/setting/addInfo')));
const MobileAccount = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/setting/account')));
const MobileGetPush = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/setting/getPush')));
const MobileInqLists = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/inquiries')));
const MobileInqInsert = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/inquiries/insert')));
const MobilePushLists = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/push')));
const MobileNoticeLists = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/notice')));
const MobileNoticeDetail = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/notice/detail')));
const MobilePushMissionLists = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/push/mission')));
const MobileEnergyStats = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/energyStats')));
const MobileEnergyStatsMonth = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/energyStats/month')));
const MobileEnergyStatsYear = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/energyStats/year')));
const MobileGenerationStats = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/generationStats')));
const MobileGenerationStatsMonth = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/generationStats/month')));
const MobileGenerationStatsYear = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/generationStats/year')));
const MobileComparison = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/comparison')));
const MobileComparisonMonth = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/comparison/month')));
const MobileComparisonYear = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/comparison/year')));
const MobileMission = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/mission')));
const MobileMissionRegist = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/mission/regist')));
const MobileMissionRequest = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/mission/request')));
const MobileMissionProgress = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/mission/progress')));
const MobileMissionEnd = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/mission/end')));
const MobileMissionClosed = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/mission/closed')));
const MobileMissionConfirmed = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/mission/confirmed')));
const MobileMissionCanceled = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/mission/canceled')));
const MobileMissionHistory = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/missionHistory')));
const MobileMissionResult = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/missionHistory/result')));
const MobilePoint = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/point')));
const MobilePointExchange = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/point/exchange')));
const MobilePassword = Loader(lazy(() => import('src/content/pages/Components/Mobiles/pages/setting/password')));

// Status
const Status:any = Loader(lazy(() => import('src/content/pages/Status')));
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));

//Layout

const BaseLayout = PageLoader(BaseLayoutComponent);
const SidebarLayout = PageLoader(SidebarLayoutComponent);

// Logins

const Logins = Loader(lazy(() => import('src/content/pages/Components/Logins')));
const HaemorningLogins = Loader(lazy(() => import('src/content/pages/Components/HaemorningLogins')));

const routes = (authentication) => [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element:
          // <Navigate to="/dashboards" replace/>
          !authentication.loginType ? (authentication.logoType === "20220001" ? <Navigate to="haemorning/login" replace /> : <Navigate to="auth/login" replace />) : (authentication.loginPath === "RES") ?  <Redirect/> :  <Navigate to='/dashboard/totalMonitoring' replace={true}  />



        //////////////////// 자원관리 개발용 임시 처리 taerang ///////////////////
        //  authentication ? <Redirect/> : <Logins /> 
        //////////////////// 자원관리 개발용 임시 처리 end ///////////////////


      },
      {
        path: 'auth/login',
        element: !authentication.loginType ? <Logins /> : (authentication.loginPath === "RES") ? <Redirect /> : <Navigate to='/dashboard/totalMonitoring' replace={true} />
      },
      
      {
        path: 'overview',
        element: <Navigate to="" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
      {
        path: 'haemorning/login',
        element: !authentication.loginType ? <HaemorningLogins /> : (authentication.loginPath === "RES") ? <Redirect /> : <Navigate to='/dashboard/totalMonitoring' replace={true} />
      },
    ]
  },
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard/totalMonitoring" replace />
      },
      {
        path: 'totalMonitoring',
        element: <Dashboards />
      },
      {
        path: 'genealogy',
        element: <Dashboards />
      },
      {
        path: 'PersonalMonitoring',
        element: <PersonalMonitoring />
      },
      {
        path: 'setting',
        element: <Dashboards />
      },
      {
        path: 'collectionMonitoring',
        element: <CollectionMonitoring />
      }
    ]
  },
  {
    path: 'popup',
    children: [
      {
        path: 'userMonitoringPopUp/:userId',
        element: <PersonalMonitoring />
      }
    ]
  },
  {
    path: 'mgr',
    element: <SidebarLayout />,
    children: [
      {
        path: 'codeLists',
        element: <CodeLists />
      },
      {
        path: 'noticeLists',
        element: <NoticeLists />
      },
      {
        path: 'powerPlantList',
        element: <PowerPlantLists />
      },
      {
        path: 'logins',
        element: <Logins />
      },
      {
        path: 'userLists',
        element: <UserLists />
      },
      {
        path: 'logins',
        element: <Logins />
      },
      {
        path: 'equipLists',
        element: <EquipLists />
      },
      {
        path: 'rtuList',
        element: <RtuLists />
      },
      {
        path: 'workHistory',
        element: <WorkHistory />
      },
      {
        path: 'errorLogLists',
        element: <ErrorLogLists />
      },
      {
        path: 'collectLogLists',
        element: <CollectLogLists />
      },
      {
        path: 'noticeLogLists',
        element: <NoticeLogLists />
      },
      {
        path: 'loginLogLists',
        element: <LoginLog />
      }
    ]
  },
  {
    path: 'service',
    element: <SidebarLayout />,
    children: [
      {
        path: 'StatLists',
        element: <StatLists />
      },
      {
        path: 'GenStatLists',
        element: <PowerGenStatLists />
      },
      {
        path: 'drLists',
        element: <DrLists />
      },
      {
        path: 'pointExchange',
        element: <PointExchange />
      },
      {
        path: 'pointHistory',
        element: <PointHistory />
      },
      {
        path: 'reportLists',
        element: <EnergyReportList />
      },
      {
        path: 'arkStatLists',
        element: <ArkStatLists />
      }
    ]
  },
  {
    path: 'cust',
    element: <SidebarLayout />,
    children: [
      {
        path: 'noticeLists',
        element: <NoticeLists />
      },
      {
        path: 'inquireLists',
        element: <InqLists />
      },
      {
        path: 'customerLists',
        element: <CustomerLists />
      }
    ]
  },
  {
    path: 'mg/mobile',
    element: <MobileLayout />,
    children: [
      {
        path: 'login',
        element: authentication.loginType ? <Navigate to="/mg/mobile/home" replace /> : <MobileLists />
      },
      {
        path: 'home',
        element: <MobileHome />
      },
      {
        path: 'findId',
        element: <MobileFindId />
      },
      {
        path: 'findPwd',
        element: <MobileFindPwd />
      },
      {
        path: 'setting',
        element: <MobileSetting />
      },
      {
        path: 'setting/profile',
        element: <MobileProfile />
      },
      {
        path: 'setting/profile/edit',
        element: <MobileProfileEdit />
      },
      {
        path: 'setting/addInfo',
        element: <MobileAddInfo />
      },
      {
        path: 'setting/account',
        element: <MobileAccount />
      },
      {
        path: 'setting/password',
        element: <MobilePassword />
      },
      {
        path: 'setting/getPush',
        element: <MobileGetPush />
      },
      {
        path: 'inquireLists',
        element: <MobileInqLists />
      },
      {
        path: 'inquireLists/insert',
        element: <MobileInqInsert />
      },
      {
        path: 'push',
        element: <MobilePushLists />
      },
      {
        path: 'noticeLists',
        element: <MobileNoticeLists />
      },
      {
        path: 'noticeLists/detail',
        element: <MobileNoticeDetail />
      },
      {
        path: 'push/mission',
        element: <MobilePushMissionLists />
      },
      {
        path: 'energyStats',
        element: <MobileEnergyStats />
      },
      {
        path: 'energyStats/month',
        element: <MobileEnergyStatsMonth />
      },
      {
        path: 'energyStats/year',
        element: <MobileEnergyStatsYear />
      },
      {
        path: 'generationStats',
        element: <MobileGenerationStats />
      },
      {
        path: 'generationStats/month',
        element: <MobileGenerationStatsMonth />
      },
      {
        path: 'generationStats/year',
        element: <MobileGenerationStatsYear />
      },
      {
        path: 'comparison',
        element: <MobileComparison />
      },
      {
        path: 'comparison/month',
        element: <MobileComparisonMonth />
      },
      {
        path: 'comparison/year',
        element: <MobileComparisonYear />
      },
      {
        path: 'mission',
        element: <MobileMission />
      },
      {
        path: 'mission/reg',
        element: <MobileMissionRegist />
      },
      {
        path: 'mission/req',
        element: <MobileMissionRequest />
      },
      {
        path: 'mission/ing',
        element: <MobileMissionProgress />
      },
      {
        path: 'mission/end',
        element: <MobileMissionEnd />
      },
      {
        path: 'mission/clsd',
        element: <MobileMissionClosed />
      },
      {
        path: 'mission/cfm',
        element: <MobileMissionConfirmed />
      },
      {
        path: 'mission/cnl',
        element: <MobileMissionCanceled />
      },
      {
        path: 'mission/history',
        element: <MobileMissionHistory />
      },
      {
        path: 'mission/history/result',
        element: <MobileMissionResult />
      },
      {
        path: 'point',
        element: <MobilePoint />
      },
      {
        path: 'point/exchange',
        element: <MobilePointExchange />
      }
    ]
  }
];

export default routes;
