import { atom, DefaultValue, selector, selectorFamily } from "recoil";
import { todoListFilterState, todoListState } from "../atoms/atoms";
import axios from "axios";

//
export const getSampleInfo = selector({
  key: 'getSampleInfo',
  get: async ({ get }) => {
    const response = await axios.get('https://reqres.in/api/login');
    // const response = await axios.get('/api/hello');
    return response.data;
    
    // get: ({ get }) => {
    // return axios.get('https://reqres.in/api/login').then((response)=>response.data);
  },
});

export const getTestListInfo = selector({
  key: 'getTestListInfo',
  get: async ({ get }) => {

    const filter = get(todoListFilterState);
    console.log("getTestListInfo");
    // const response = await axios.get('https://reqres.in/api/login');
    // const response = await axios.get('/api/hello/test');
    const response = await axios.get('/test');
    return response.data;
	   
  },
});

/**
 * 코드 목록 조회
 */
export const getCodeList = selector({
	key : 'getCodeList',
	get : async ({ get }) => {
		const response = await axios.get('/codeList');
		return response.data;
	}
});

/**
 * 코드그룹 목록 조회
 */
export const getCodeGroupList = selector({
	key : 'getCodeGroupList',
	get : async ({ get }) => {
		const response = await axios.get('/codeGroupList');

		return response.data;
	}
});

/**
 * 공지사항 조회
 */
 export const getNoticeList = selector({
	key : 'getNoticeList',
	get : async ({ get }) => {
		const response = await axios.get('/noticeList');
		return response.data;
	}
});

/**
 * 발전소 조회
 */
 export const getPowerPlantList = selector({
	key : 'getPowerPlantList',
	get : async ({ get }) => {
		const response = await axios.get('/powerPlantList');
		return response.data;
	}
});

/**
 * 발전소 지역 조회
 */
 export const getPowerPlantAddrList = selector({
	key : 'getPowerPlantAddrList',
	get : async ({ get }) => {
		const response = await axios.get('/powerPlantAddrList');
		return response.data;
	}
});

/**
 * RTU 조회
 */
 export const getRtuList = selector({
	key : 'getRtuList',
	get : async ({ get }) => {
		const response = await axios.get('/rtuList');
		return response.data;
	}
});

/**
 * 사업 콤보 목록 조회
 */
 export const getBizBjdongComboList = selector({
	key : 'getBizBjdongComboList',
	get : async ({ get }) => {
		const response = await axios.get('/bizBjdongComboList');

		return response.data;
	}
});

const counter = atom({
	key: 'paramBdSeq',
	default: 0,
  });

/**
 * 게시판 덧글 목록 조회
 */
 export const getBdReplyList = selectorFamily({
	key : 'getBdReplyList',
	get : (bdSeq) => async ({ get }) => {
		const filter = get(todoListFilterState);

		const params = new URLSearchParams();

		params.append('bdSeq', bdSeq.toString());

		const response = await axios.post('/getBdReplyList', params);

		return response.data;
	}
});

/**
 * 게시판 첨부파일 목록 조회
 */
 export const getBdFileList = selector({
	key : 'getBdFileList',
	get : async ({ get }) => {
		const response = await axios.get('/getBdFileList');

		return response.data;
	}
});


/**
 * 메뉴 목록 조회
 */
 export const getMenuList = selector({
	key : 'getMenuList',
	get : async ({ get }) => {
		
		const params = new URLSearchParams();
		const response = await axios.post('/menuList', params).catch((err)=>{throw err});

		// 조회 후처리 함수
		let data = response.data;

		let menuItem = [];
		let message = '';

		if(data.result === "SUCCESS") {
			// 리스트 변환 후 저장
			const list = data.data.menuList;
			// let menuItem = [];
			let items = [];
			let pMenuId = '';
			let pMenuNm = '';

			for (let i = 0; i < list.length; i++) {
				// 초기 상위 메뉴 셋팅
				if(i === 0) {
					pMenuId = list[i].menuId;
					pMenuNm = list[i].menuNm;
				} else {
					// 현재 상위 메뉴에 속할 경우
					if(pMenuId === list[i].pMenuId) {
						// 상위 메뉴가 아닐 경우 아이템 셋팅
						if(list[i].menuStp !== 1 && list[i].useYn !== 'N') {
							items.push({
								name: list[i].menuNm,
								// icon: TableChartTwoToneIcon,
								link: list[i].menuUrl
							});
						}
					} else {
						menuItem.push({
							heading: pMenuNm,
							menuId: pMenuId,
							items: items
						});

						items = [];
						pMenuId = list[i].menuId;
						pMenuNm = list[i].menuNm;
					}

					// 마지막 셋팅
					if(i === list.length - 1) {
						menuItem.push({
							heading: pMenuNm,
							menuId: pMenuId,
							items: items
						});
					}
				}
			}

			menuItem = menuItem;
			message = data.message;

		} else {
			message = data.message;
		}
	
		let resource = {
			menuList: menuItem,
			message: message
		};

		return resource;
	}
});



