import {
  Box,
  Button,
  OutlinedInput,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'src/contexts/AuthenticationContext';
import { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { KeyboardBackspaceOutlined } from '@mui/icons-material';

function Status401(props) {

  const { data } = props
  const { code, message }= data

  const auth = useAuth();

/*  
  const navigate = useNavigate();
*/  
/*  
  const clearCache = useRecoilCallback(({snapshot, refresh, reset, gotoSnapshot}) => async () => {
    
    try {
      
      const nodeData = snapshot.getNodes_UNSTABLE();
      const nodes = Array.from(nodeData);

      for (let i in nodes) {
        let node:any = nodes[i];
        refresh(node);
      }

      return;
    } catch (error) {
      throw error;
    }
  
  });
*/

  
  useEffect(() => {
    
    if(code == "A002"){
      
      auth.logout();

      // clearCache().then(()=>{
      //   auth.logout();
      // })
   
      // "A001", "인증이 필요합니다."
      // "A002", "인증이 만료 되었습니다."
      // "A003", "알수 없는 에러가 발생했습니다.

    }
  }, []);
  
  return (
    <>
      {message}
      {/* <Button onClick={()=>{navigate("/", { replace: true });}}>로그인 화면</Button> */}
      <Button onClick={()=>{window.location.href = "/";}}>로그인 화면</Button>
    </>
  );
}

export default Status401;
