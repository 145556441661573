import axios from 'axios';
import { TOKEN_NM } from './content/pages/Components/CommonData/CommonData';

/////////////////////// 로그인 인증 관련 by taerang //////////////////////
// const axiosDefaultSetting =  (auth, navigate)=>{

  window.fnCallbackPrefVal = async (param) => {
    let callbackData = JSON.parse(param)
    if (callbackData.key === TOKEN_NM.ACCESS) {
      axios.defaults.headers.common['Authorization'] = callbackData.val
    } else {
      axios.defaults.headers.common['AuthorizationRef'] = callbackData.val
    }
  }

const getIosToken = async (tokenType) => {
		let params:any = {'options':{'key':tokenType},'callback':{'name':'window.fnCallbackPrefVal'}}
  	params = JSON.stringify(params);
		await window.webkit.messageHandlers.ifGetPrefVal.postMessage(params);
	}

const axiosDefaultSetting = () => {
  var agent = navigator.userAgent.toLowerCase();
  if (agent.indexOf("iphone") > -1 || agent.indexOf("ipad") > -1 || agent.indexOf("ipod") > -1) { 
    getIosToken(TOKEN_NM.ACCESS)
    getIosToken(TOKEN_NM.REFRESH)
  } else {
    if (localStorage.getItem(TOKEN_NM.ACCESS) != null) {
      axios.defaults.headers.common['Authorization'] = localStorage.getItem(
        TOKEN_NM.ACCESS
      );
    }
  
    if (localStorage.getItem(TOKEN_NM.REFRESH) != null) {
      axios.defaults.headers.common['AuthorizationRef'] = localStorage.getItem(
        TOKEN_NM.REFRESH
      );
    }
  }
  //인증 만료 처리 필요
  //토큰 만료 시간 설정 필요
  //로그인 인증 후 유저정보 조회해서 처리하게끔 처리 검토(기존 localStorage 사용)

  //single page application 처음 오픈 했을때 가지고 있는 시큐어 쿠키로 로그인이 되어있는 상태인지 확인하는 절차 1번 필요
  
  // console.log(localStorage.getItem(TOKEN_NM.ACCESS) == null);
  

  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = process.env.REACT_APP_URL; //백엔드 REST API 서버 URI
  // axios.defaults.baseURL = "http://localhost:8080"; //백엔드 REST API 서버 URI
  // axios.defaults.baseURL = "http://58.125.31.102:5880"; //백엔드 REST API 서버 URI
  // axios.defaults.baseURL = "http://winergy.kr:5880"; //백엔드 REST API 서버 URI
  // axios.defaults.baseURL = "https://localhost:8443"; //백엔드 REST API 서버 URI
  // axios.defaults.baseURL = "https://58.125.31.102:5843"; //백엔드 REST API 서버 URI
  // axios.defaults.baseURL = "https://192.168.0.159:8443"; //백엔드 REST API 서버 URI

};

export default axiosDefaultSetting;
