import { DialogContent, Paper, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CommonDialog from 'src/components/AlertDialog';
import DetailView from 'src/components/Common/DetailView';
import { ItemGroup, PopupFormControlLabel } from "src/components/Common/ItemGroup";
import { useTrans } from 'src/i18n/locales/useTrans';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

// 아이디 / 권한 / 관리사업 / 관리지역 수정 불가
function UserProfiles(props) {
	const $ = useTrans();
	const { onClose, open, data } = props;
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertCont, setAlertCont] = useState('');
	const [alertTitle, setAlertTitle] = useState('');
	const [editMode, setEditMode] = useState(false);
	const [checkPwd, setCheckPwd] = useState('');
	const [loginUserInfo, setLoginUserInfo] = useState<any> ([]);
	const [userListUpdateParam, setUserListUpdateParam] = useState({
		userId: '',
		userNm: '',
		userPwd: '',
		mobileNo: '',
		email: '',
		userAuthTypeCd: '',
		userAuthTypeNm: '',
		bizId: '',
		bizNm: '',
		bjdongCd: '',
	});

	const [paramError, setParamError] = useState({
		userNm: '',
		mobileNo: '',
		email: '',
		userAuthTypeCd: '',
		bjdongCd: '',
		checkPwd: '',
		userPwd : ''
	});

	const [bjdongCdArr, setBjdongCdArr] = useState([]);

	const handleChange = name => (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserListUpdateParam({...userListUpdateParam, [name] : event.target.value});
		setParamError({...paramError, [name] : ''});
	};

	const checkPwdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckPwd(event.target.value);

		if(event.target.value !== '') {
			setParamError({...paramError, checkPwd: ''})
		}
	}

	const validation = () => {
		let check = false;
		const emailReg = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
		const mobileNoReg = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;

		if (userListUpdateParam.userNm === '') {
			// 이름 체크
			setAlertCont($('required', ['이름은']));
		}
		else if (userListUpdateParam.userPwd === '') {
			// 비밀번호
			setAlertCont($('required', ['비밀번호는']));
		}
		else if (checkPwd === '') {
			// 비밀번호확인
			setAlertCont($('required', ['비밀번호 확인은']));
		}
		else if (userListUpdateParam.userPwd != checkPwd) {
			// 비밀번호&비밀번호확인 equal 검사
			setAlertCont($('noEqual', ['비밀번호가']));
		}
		else if (!mobileNoReg.test(userListUpdateParam.mobileNo)) {
			// 핸드폰 체크
			setAlertCont($('noMatch', ['핸드폰 번호']));
		}
		else if(userListUpdateParam.email != ''){
			if (!emailReg.test(userListUpdateParam.email)) {
				// 이메일 체크
				setAlertCont($('noMatch', ['이메일']));
			}
		}
		else if (userListUpdateParam.userAuthTypeCd === '') {
			// 권한 체크
			setAlertCont($('required', ['권한은']));
		}
		else if (bjdongCdArr.length === 0 && userListUpdateParam.userAuthTypeCd !== 'ADM') {
			// 관리지역 체크
			setAlertCont($('required', ['관리지역은']));
		} else {
			check = true;
		}

		if (!check) {
			setAlertTitle('필수 체크');
			setAlertOpen(true);
		}

		return check;
	}

	const handleClose = () => {
		// 기존 데이터로 초기화
		setUserListUpdateParam({
			...userListUpdateParam,
			userNm : loginUserInfo.userNm,
			mobileNo : loginUserInfo.mobileNo,
			email : loginUserInfo.email,
			userAuthTypeCd : loginUserInfo.userAuthTypeCd,
			userPwd: ''
		});
		setCheckPwd('')

		if(editMode) {
			setEditMode(false);
			document.getElementById('userProfileEditBtn').textContent = '수정';
			document.getElementById('userProfileCloseBtn').textContent = '닫기';
		} else {
			onClose(false);
		}
	};

	const chgEditMode = (event: React.MouseEvent<HTMLButtonElement>) => {
		if(editMode) {
			updateUserListRequest();
		} else {
			setEditMode(true);
		}
	}

	const loginUserInfoRequest = () => {
		axios.post("/loginUserInfo")
		.then(function(response) {
			const user = response.data.data.loginUserInfo[0];

			setLoginUserInfo(user);

			setUserListUpdateParam({
				userId: user.userId,
				userNm: user.userNm,
				userPwd: '',
				mobileNo: user.mobileNo,
				email: user.email,
				userAuthTypeCd: user.userAuthTypeCd,
				userAuthTypeNm: user.userAuthTypeNm,
				bizId: user.bizId,
				bizNm: user.bizNm,
				bjdongCd: user.bjdongCd,
			});

			
			if(user.bjdongCd != "") {
				setBjdongCdArr(user.bjdongNm.toString().split(','));
			}
		}).catch(function(error) {
			// 오류시 처리 함수
			console.log(error);
		})
	}

	const updateUserListRequest = () => {
		// 필수,데이터 체크
		if(!validation()) {
			return false;
		}

		const params = new URLSearchParams();

		params.append('userId', userListUpdateParam.userId);
		params.append('userNm', userListUpdateParam.userNm);
		params.append('userPwd', userListUpdateParam.userPwd);
		params.append('mobileNo', userListUpdateParam.mobileNo);
		params.append('email', userListUpdateParam.email);
		params.append('userAuthTypeCd', userListUpdateParam.userAuthTypeCd);

		axios.post("/updateUserProfile",
			params
		).then(function(response) {
			const data = response.data;

			if(data.result === "SUCCESS") {
				// 팝업 닫기 후 변경 데이터 셋팅
				onClose(true);
			} else {
				setAlertCont(data.message);
				setAlertOpen(true);
			}
		}).catch(function(error) {
			// 오류시 처리 함수
			console.log(error);
		});
	};

	const handleAlertClose = () => {
		setAlertOpen(false);
	};

	useEffect(() => {
		if(open) {
			setParamError({
				userNm: '',
				mobileNo: '',
				email: '',
				userAuthTypeCd: '',
				bjdongCd: '',
				checkPwd: '',
				userPwd: '',
			});
			setCheckPwd("");
			// 사용자 정보 조회
			loginUserInfoRequest();

			setEditMode(false);
		}
	},[open, data]);

	const POPUP_WIDTH:string = '530px';
	return(
		<DetailView title="내정보 수정" open={open} onClose={handleClose} onOk={chgEditMode} editMode={editMode}>
			<DialogContent dividers sx={{flexGrow:1, maxWidth: POPUP_WIDTH}}>
				<ItemGroup>
					<PopupFormControlLabel control={
						<TextField 
							size="small" fullWidth disabled
							value={userListUpdateParam.userId}
						/>
					} label='아이디*' labelPlacement='start'/>

					<PopupFormControlLabel control={
						<TextField 
							size="small" fullWidth disabled={!editMode}
							inputProps={{maxLength: 30}}
							value={userListUpdateParam.userNm}
							onChange={handleChange("userNm")}
						/>
					} label='이름*' labelPlacement='start'/>

					<PopupFormControlLabel control={
							<TextField 
								size="small" type="password" fullWidth disabled={!editMode}
								inputProps={{maxLength: 16}}
								value={userListUpdateParam.userPwd}
								onChange={handleChange("userPwd")}
							/>
					} label='비밀번호*' labelPlacement='start'/>

					<PopupFormControlLabel control={
							<TextField 
								size="small" type="password" fullWidth disabled={!editMode}
								inputProps={{maxLength: 16}}
								value={checkPwd}
								onChange={checkPwdChange}
							/>
					} label='비밀번호 확인*' labelPlacement='start'/>

					<PopupFormControlLabel 
						style={{color: 'red'}}
						control={<div></div>}
						label='※10~16자 영문 대소문자, 숫자, 특수문자를 최소 3종류 조합하세요.'
						labelPlacement='start'
					/>

					<PopupFormControlLabel control={
						<TextField
							size="small" fullWidth disabled={!editMode}
							inputProps={{maxLength: 12}}
							value={userListUpdateParam.mobileNo}
							onChange={handleChange("mobileNo")}
						/>
					} label='핸드폰*' labelPlacement='start'/>

					<PopupFormControlLabel control={
						<TextField
							size="small" fullWidth disabled={!editMode}
							inputProps={{maxLength: 100}}
							value={userListUpdateParam.email}
							onChange={handleChange("email")}
						/>
					} label='이메일' labelPlacement='start'/>

					<PopupFormControlLabel control={
						<TextField
							size='small' fullWidth disabled
							value={userListUpdateParam.userAuthTypeNm}
						/>
					} label='권한*' labelPlacement='start'/>

					<PopupFormControlLabel control={
						<TextField
							size='small' fullWidth disabled
							value={userListUpdateParam.bizNm}
						/>
					} label='관리사업*' labelPlacement='start'/>

					<PopupFormControlLabel control={
						<TextField
							size='small' fullWidth disabled
							value={bjdongCdArr}
						/>
					} label='관리지역*' labelPlacement='start'/>
				</ItemGroup>
			</DialogContent>
			<CommonDialog open={alertOpen} onClose={handleAlertClose} content={alertCont}/>
		</DetailView>
	)
}

export default UserProfiles;