import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, ReactNode, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/contexts/AuthenticationContext';

interface MobileLayoutProps {
	children?: ReactNode;
}

const MainWrapper = styled(Box)(
	({ theme }) => `
		  flex: 1 1 auto;
		  display: flex;
		  height: 100%;
  `
);

const MainContent = styled(Box)(
	({ theme }) => `
		  flex: 1 1 auto;
		  overflow: auto;
		  margin-bottom : 10px;
  `
);



const MobileLayout: FC<MobileLayoutProps> = () => {
	const auth = useAuth();
	const navigate = useNavigate();
	useEffect(() => {
		if (!auth.authentication) {
			navigate(`/mg/mobile/login`);
		}
	},[])
	return (
		<>
			<MainWrapper>
				<MainContent>
					<Outlet />
				</MainContent>
			</MainWrapper>
		</>
	);
};

export default MobileLayout;