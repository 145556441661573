
import { atom } from "recoil";
import { TodoItem, TodoFilter } from "../types";

export const todoListState = atom<String>({
  key: "todoListState",
  default: "test11",
});

export const todoListFilterState = atom<number>({
  key: "todoListFilterState",
  // key: "todoListState",
  default: 0,
});


