
import { atom } from "recoil";
import { layoutChangeType, dashboardType, dashboardGridKeyType, gridLayoutSettingProcessType,  dashboardGridNameType, adminRegionPowerPlantParameterType, dashboardPropType } from "../types";




/////////////대시보드 공통///////////////////
export const dashboardRecoilClearState = atom<dashboardType>({
  key: "dashboardRecoilClearState",
  default: null,
});
///////////////////////////////////////

/////////////대시보드///////////////////
export const dashboardGridKeyState = atom<dashboardGridKeyType>({
  key: "dashboardGridKeyState",
  default: null,
});
// export const dashboardGridNameState = atom<dashboardGridNameType>({
//   key: "dashboardGridNameState",
//   default: null,
// });
// export const dashboardPropState = atom<dashboardPropType>({
//   key: "dashboardPropState",
//   default: {
//     dashboardKey: null,
//     dashboardName: null,
//     editableYn:false,
//     rpstvYn:false,
//   },
//   dangerouslyAllowMutability: true
// });
// export const dashboardPropFormState = atom<dashboardPropType>({
//   key: "dashboardPropFormState",
//   default: {
//     dashboardKey: null,
//     dashboardName: null,
//     editableYn:false,
//     rpstvYn:false,
//   },
//   dangerouslyAllowMutability: true
// });





export const dashboardEditActiveState = atom<boolean>({
  key: "dashboardEditActiveState",
  default: false,
});

///////////////////////////////////////

/////////////대시보드 설정////////////////
export const gridLayoutSettingProcessState = atom<gridLayoutSettingProcessType>({
  key: "gridLayoutSettingProcessState",
  default: {
    layout:[],
    changeType: null
  }
});

export const dashboardRefreshState = atom<boolean>({
  key: "dashboardRefreshState",
  default: false,
});

//장애 선택 상태 
export const dashboardErrorStatus = atom<any>({
  key: "dashboardErrorStatus",
  default: {
    'CRI': true,
    'MAJ': true
  }
});

//지역 상태
export const dashboardRegionStatus = atom<any>({
  key: "dashboardRegionStatus",
  default: null
});

//지역 결과
export const dashboardRegionValue = atom<string>({
  key: "dashboardRegionValue",
  default: '',
});

//사업 상태
export const dashboardBizStatus = atom<any>({
  key: "dashboardBizStatus",
  default: null
});

//사업 결과
export const dashboardBizValue = atom<string>({
  key: "dashboardBizValue",
  default: '',
});

//에너지 종합 현황의 지역 상태
export const dashboardOverallRegion = atom<any>({
  key: "dashboardOverallRegion",
  default: null
});

//에너지 소비 대체율의 지역 상태
export const dashboardEsrrRegion = atom<any>({
  key: "dashboardEsrrRegion",
  default: null
});

//태양광 발전량 추이의 지역 상태
export const dashboardSolarRegion = atom<any>({
  key: "dashboardSolarRegion",
  default: null
});

//지도 장애상태
export const dashboardMapErrorStatus = atom<any>({
  key: "dashboardMapErrorStatus",
  default: {
    'normal': true,
    'error': true
  }
});
/////////////////////////////////////////


// /////////////대시보드 위젯////////////////
// export const adminRegionPowerPlantParameterState = atom<adminRegionPowerPlantParameterType>({
//   key: "adminRegionPowerPlantParameterState",
//   default: {
//     bjdongCd:'0000000000'
//   }
// });
// /////////////////////////////////////////







