import { useLayoutEffect } from 'react';


function Redirect() {

    // JSP 화면 테스트
    useLayoutEffect(()=> {
      // if(true){ // 서비스 권한 및 디폴트 페이지에 따라 
      // if(auth.authentication){ // 로그인 이후 
        //자원관리 서브 도메인
        const subDomain = '/rm';

        // window.location.replace(subDomain + '/site');
        window.location.replace(`/rm/site`);
      // }
    });

  return (
   <></>
  );
}

export default Redirect;
