import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { debounce } from 'lodash';
import { FC, ReactNode, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { MINIMUM_WIDTH_SIZE } from 'src/content/pages/Components/CommonData/CommonData';
import { useAuth } from 'src/contexts/AuthenticationContext';
import Header from './Header';
import Sidebar from './Sidebar';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;

        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${theme.sidebar.width};
        }

        @media (min-width: ${theme.breakpoints.values.sm}px) {
          padding-left: ${theme.sidebar.width};
        }

      

        // min-width: ${theme.breakpoints.values.lg}px
        // padding-left: ${theme.sidebar.width};
        // min-width: ${theme.breakpoints.values.xl - parseInt(theme.sidebar.width)}px;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);



const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const { windowSize } = useAuth();
  const size = useWindowSize();
  if (windowSize.width <= MINIMUM_WIDTH_SIZE) {
  return (
    <>
      {/* <Sidebar /> */}
      {/* <MainWrapper> */}
      <Header windowWidth={windowSize.width} />
        <MainContent>
          <Outlet />
        </MainContent>
      {/* </MainWrapper> */}
    </>
    );
  } else {
    return (
      <>
        <Sidebar />
        <MainWrapper>
          <Header windowWidth={windowSize.width} />
          <MainContent>
            <Outlet />
          </MainContent>
        </MainWrapper>
      </>
      );
  }
};

// ------------------- customHook -----------------------
function useWindowSize() {
	const { windowSize, setWindowSize } = useAuth();
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	
	useEffect(() => {
	  // Handler to call on window resize
    const handleResize = debounce(()=> {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 1000);
	  // Add event listener
	  window.addEventListener("resize", handleResize);
	  // Call handler right away so state gets updated with initial window size
	  handleResize();
	  // Remove event listener on cleanup
	  return () => window.removeEventListener("resize", handleResize);
	}, []); // Empty array ensures that effect is only run on mount
	return windowSize;
}
  // ------------------- customHook -----------------------

export default SidebarLayout;
