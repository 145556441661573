
import {

  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useAuth } from 'src/contexts/AuthenticationContext';


const LogoutBox = styled(Box)`


  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #d9d9d9;
  cursor: pointer;

`;

function HeaderLogout() {

  ////////////////////로그인 인증 관련
  const navigate = useNavigate();
  const auth = useAuth();
  const principal = auth.getPrincipal();
  ////////////////////로그인 인증 관련 end

  const logout = ()=>{
    axios.get("/logoutProc").then((response)=>{
      let axiosCommonHeaders = axios.defaults.headers.common;
      delete axiosCommonHeaders['Authorization'];
      auth.logout();
      navigate("/", { replace: true });
    }).catch((e)=>{
      console.log(e);
    })
  }

  return (
    <LogoutBox
      onClick={logout}
      title="로그아웃"
    >
      <img
        src="/static/images/new_logout_icon.png"
        width={24}
        height={24}
        alt="logo"
      />
    </LogoutBox>
  );
}

export default HeaderLogout;
