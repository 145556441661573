import {
  alpha,
  Badge,
  Box,
  Divider, IconButton,
  List,
  ListItem,
  Popover,
  Radio,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { useAuth } from 'src/contexts/AuthenticationContext';


const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function HeaderNotifications() {
  const { themeName , _setThemeName} = useAuth()
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  // const [selectedValue, setSelectedValue] = useState('PureLightTheme');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    _setThemeName(event.target.value);
    if (event.target.value === "PureLightTheme") {
      localStorage.setItem('appTheme', "PureLightTheme");
    } else {
      localStorage.setItem('appTheme', "NebulaFighterTheme");
    }
  };
  return (
    <div style={{ height: "100%", borderLeft: "solid 1px #d9d9d9" ,paddingLeft: "10px",
    paddingRight: "10px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer" } }>
      <Tooltip arrow title="화면모드 설정">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
        <img src="/static/images/darkmode_icon.png" />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        sx={{
          backdropFilter: "blur(0px)",
          //other styles here
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box sx={{ p: 2 }} display="flex" alignItems="center" justifyContent="space-between">
          <div style={{
            // border: "solid 1px ",
            paddingTop: "30px",
            paddingLeft: "40px"
          }}>
              <div>
                <img src="/static/images/Group_6042.png"></img>
              </div>
              <div style={{textAlign:"center",fontWeight:"bold"}}>
                다크 모드 
              </div>
              <div style={{ textAlign: "center" }}>
              <Radio
                checked={themeName === 'NebulaFighterTheme'}
                onChange={handleChange}
                value="NebulaFighterTheme"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'NebulaFighterTheme' }}
              />
              </div>
          </div>
          <div style={{
            // border: "solid 1px ",
            paddingTop: "30px",
            paddingRight: "40px"
          }}>
              <div>
                <img src="/static/images/Group_6043.png"></img>
              </div>
              <div style={{textAlign:"center",fontWeight:"bold"}}>
                라이트 모드
              </div>
            <div style={{ textAlign: "center" }}>
            <Radio
                checked={themeName === 'PureLightTheme'}
                onChange={handleChange}
                value="PureLightTheme"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'PureLightTheme' }}
              />
              </div>
          </div>
        </Box>


        <Divider />
        <List sx={{ p: 0 }}>
          <ListItem sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}>
            <Box flex="1">
              <Box display="flex" justifyContent="space-between">
                <Typography sx={{ fontWeight: 'bold' }}>
                  다크/라이트 설정
                </Typography>
                {/* <Typography variant="caption" sx={{ textTransform: 'none' }}>
                  {formatDistance(subDays(new Date(), 3), new Date(), {
                    addSuffix: true
                  })}
                </Typography> */}
              </Box>
              {/* <Typography
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {' '}
                new messages in your inbox
              </Typography> */}
            </Box>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
}

export default HeaderNotifications;
