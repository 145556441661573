import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Paper, styled, TextareaAutosize, TextField, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import dayjs from "dayjs";
import { Fragment, useEffect, useState, useRef } from "react";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
	  
    },
  },
};




const Title = styled(Box)`
  font-weight: 700;
  font-size: 16px;
  color: #121212;
  font-family: 'SpoqaHanSansNeo';
  margin-bottom: 12px;
`;

const InputBox = styled(Box)`
  margin: 0;
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
  flex-grow:1; 
//   margin-bottom: 16px;
`;

const Label = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f;
  font-family: 'SpoqaHanSansNeo';
  margin-bottom: 5px;
`;

const StyledTextField = styled(TextField)(({ theme }) => ({
	padding: 0,
	// height: '44px',
	// background: '#c7c7c7',
	border: 'none',
	// height: '44px',
	// background: '#F8F8F8',
	borderRadius: '3px',
	width: '100%',
	'& .MuiOutlinedInput-input': {
		padding: '11px 14px'
	},

	'& .MuiInputBase-root': {
		padding: 0,
		background: theme.palette.background.default,
	},

  }));

  const StyledButton = styled(Button)(({ theme }) => ({
	background: 'red',
    color: '#fff',
    borderRadius: '4px',
    width: '80px'
  }))

  const StyledButton2 = styled(Button)(({ theme }) => ({
	background: '#fff',
    color: 'red',
    border: 'solid 1px',
    width: '60px',
    height: '30px',
	borderRadius: '4px'
  }))





const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
	padding: 0,
	height: '44px',
	// background: '#c7c7c7',
	border: 'none',
	// height: '44px',
	background: '#F8F8F8',
	borderRadius: '3px',
	width: '100%',
	// width: 'calc(100% - 41.59px);',
	'& .MuiOutlinedInput-input': {
		padding: '11px 14px'
	},

	// '& .MuiInputBase-root.MuiOutlinedInput-root ': {
	// 	width: 'calc(100% - 41.59px)'
	// },
  }))




function DetailView(props) {
	const { onClose, open, onDelete, onOk, editMode , deleteBtnVisible } = props;
  	// const [editMode, setEditMode] = useState(false);

	function onOkHandler() {



		onOk();
	}


	
	return (
		<Dialog 
        open={open}
		maxWidth='xl'
		onClose={onClose} 
		
		>
			<DialogTitle sx={{
				color: (theme) => theme.palette.text.primary,
				fontFamily: 'Spoqa Han Sans Neo',
				fontStyle: 'normal',
				fontWeight: 700,
				fontSize: '20px', 
			}}>{props.title}</DialogTitle>
			
			<IconButton aria-label="close" 
        onClick={onClose}
		
        sx={{ position: 'absolute', right: 8, top: 7, color: (theme) => theme.palette.grey[500] }}>
				<GridCloseIcon/>
			</IconButton>

			{/* <Box sx={{ background: '#F8F8F8'}}> */}
       			{props.children}
			{/* </Box> */}
			{/* footer  */}
			{editMode
			?
			<DialogActions sx={{justifyContent: 'center', background: (theme) => theme.palette.mode === 'dark' ? '#292E38' : '#EFEFEF'}}>
				<Box sx={{flexGrow:1, width:'100%'}}>
						{deleteBtnVisible == false ? null :<StyledButton2 id='boardDeleteBtn' onClick={onDelete}>삭제</StyledButton2>}
				</Box>
				<Box sx={{flexGrow:1, width:'100%', display: 'flex', justifyContent: 'center'}}>
					<StyledButton sx={{background: '#898989'}} id='boardEditCloseBtn' onClick={onClose}>취소</StyledButton>
					<Box sx={{width:'8px'}}/>
					<StyledButton sx={{background: '#1E4DBA'}} id='boardEditBtn' 
            onClick={onOk}>
              저장</StyledButton>
				</Box>
				<Box sx={{flexGrow:1, width:'100%'}}>
					
				</Box>
			</DialogActions>
			:
			<DialogActions 
				sx={{
					justifyContent: 'center', 
					background: (theme) => theme.palette.mode === 'dark' ? '#292E38' : '#EFEFEF',
					'.MuiBox-root':{
							margin: '0px'
						}
					}}
				>
					
					<Box sx={{flexGrow:1, width:'100%'}}>
						{onDelete != null ?<StyledButton2 id='boardDeleteBtn' onClick={onDelete} >삭제</StyledButton2>: null}
					</Box>
					
					<Box sx={{flexGrow:1, width:'100%', display: 'flex', justifyContent: 'center'}}>
						<StyledButton sx={{background: '#898989'}} id='boardEditCloseBtn' 
              onClick={onClose}>닫기</StyledButton>
						<Box sx={{width:'8px'}}/>
						{ onOk != null ?  <StyledButton sx={{background: '#1E4DBA'}} id='boardEditBtn' onClick={onOkHandler}>수정</StyledButton>
						: null }
					</Box>
					<Box sx={{flexGrow:1, width:'100%'}}>
					
					</Box>
			</DialogActions>
			}

		
		</Dialog>
	);
}

export default DetailView