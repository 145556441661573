import { useNaverMapInit } from "@r2don/react-naver-map";
import { FC, useState, createContext } from 'react';
type NaverMapInitContext = { isLoaded: any; };

export const NaverMapInitContext = createContext<NaverMapInitContext>(
  {} as NaverMapInitContext
);

export const NaverMapInitProvider = ({ children }) => {

  const {isLoaded} = useNaverMapInit({ //네이버 지도 api 스크립트 불러오기
    ncpClientId: "t8s6lowv7i",
    subModules: ["geocoder"]
    // subModules: Array<String>;  
    // onLoad?: VoidFunction;
    // onError?: VoidFunction;
  });

  return (
    <NaverMapInitContext.Provider value={{ isLoaded }}>
      {children}
    </NaverMapInitContext.Provider>
  );
};
