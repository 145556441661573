import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useRoutes } from 'react-router-dom';
import routes from './router';

import { CssBaseline } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useAuth } from './contexts/AuthenticationContext';
import ThemeProvider from './theme/ThemeProvider';

import { useEffect } from 'react';
import axiosDefaultSetting from './request';
import { ko } from "date-fns/locale";

declare global {
	interface Window {
		connect: any;
		webkit: any;
		fnCallbackPushToken: (param: any) => void;
		fnCallbackPrefVal: (param: any) => void;
	}
}

const App = () => {
  
  axiosDefaultSetting(); //axios 초기 세팅
  const { authentication, tokenCheckUserInfo } = useAuth();
  const content = useRoutes(routes(authentication.current));
  const ifCloseSplash=()=> {
		let params : any = {
			options: {
				animation: 'slide'
			}
		}
		params = JSON.stringify(params)
	
		if (window.connect) {
			// AOS
			window.connect.ifCloseSplash(params);
			console.log("WEB->AOS :  ifCloseSplash : " + params)
		} else if (window.webkit) {
			// IOS
			window.webkit.messageHandlers.ifCloseSplash.postMessage(params)
			console.log("WEB->IOS :  ifCloseSplash : " + params)
		} else {
			// WEB or ERROR
			console.log("WEB :  ifCloseSplash : " + params)
		}
	}

  const defaultSetting = async () => {
    await ifCloseSplash()
    await tokenCheckUserInfo()
  }
  
  useEffect( () => {
    defaultSetting()
  }, [])
  

  return (
    <ThemeProvider>
      {/* <AuthenticationProvider> */}
        <Helmet>
          <title>{process.env.REACT_APP_TITLE}</title>
        </Helmet>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
          <CssBaseline />
          {content}
        </LocalizationProvider>
      {/* </AuthenticationProvider> */}
    </ThemeProvider>
  );  
}
export default App;