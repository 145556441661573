import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled, TextField } from "@mui/material";
import { GridCloseIcon } from '@mui/x-data-grid';
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import CommonDialog from "src/components/AlertDialog";
import { ItemGroup, PopupFormControlLabel } from "src/components/Common/ItemGroup";
import { useAuth } from 'src/contexts/AuthenticationContext';
import { maskingState, maskingTimerState } from "src/recoil/selectors/sessionSelectors";

const StyledButton = styled(Button)(({ theme }) => ({
	background: 'red',
    color: '#fff',
    borderRadius: '4px',
    width: '80px'
}));

export default function MaskingClear(props) {
	const { onClose, open } = props;

	// 알럿
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertCont, setAlertCont] = useState('');

	// 마스킹
	const [maskClearTime, setMaskClearTime] = useRecoilState(maskingTimerState);
	const [maskClearYn, setMaskClearYn] = useRecoilState(maskingState);
	const timerRef = useRef<any>();
	const resetTimer = useResetRecoilState(maskingTimerState);

	const [ maskingClearParam, setMaskingClearParam ] = useState({userId: '', userPwd: ''});
	const [ paramError, setParamError ] = useState({userId: '', userPwd: ''});

	const auth = useAuth();
    const principal = auth.getPrincipal();
	const { userId } = principal;

	const handleMaskingClearClose = () => {
		onClose(false);
	};

	const handleAlertClose = () => {
		setAlertOpen(false);
	};

	const handleMaskingClear = () => {
		const params = new URLSearchParams();

		params.append('userId', maskingClearParam.userId);
		params.append('userPwd', maskingClearParam.userPwd);

		axios.post("/maskingClear",
			params
		).then(function(response) {
			const data = response.data;

			if(data.result === "SUCCESS") {
				// 마스킹 여부 수정
				SettingMaskTimer();

				// 팝업 닫기 후 변경 데이터 셋팅
				onClose();
			} else {
				setAlertCont(data.message);
				setAlertOpen(true);
			}
		}).catch(function(error) {
			// 오류시 처리 함수
			console.log(error);
		});
	};

	const SettingMaskTimer = () => {
		// 마스킹 여부 수정
		setMaskClearYn('Y');
		resetTimer();

		timerRef.current = setInterval(() => {
			setMaskClearTime((prevMarkerClearTime) => {
				if(prevMarkerClearTime > 0) {
					return prevMarkerClearTime -1;
				}
				return prevMarkerClearTime;
			});
		}, 1000);

	}

	useEffect(() => {
		if(maskClearTime <= 0) {
			// 마스킹 여부 초기화
			setMaskClearYn('N');
			clearInterval(timerRef.current);
		}
	}, [maskClearTime]);

	const handleChange = name => (event: React.ChangeEvent<HTMLInputElement>) => {
		setMaskingClearParam({...maskingClearParam, [name] : event.target.value});
		setParamError({...paramError, [name] : ''});
	};

	useEffect(() => {
		if(open) {
			setMaskingClearParam({userId: userId, userPwd: ''});
			setParamError({userId: '', userPwd: ''});
		}
	}, [open]);


	const POPUP_WIDTH:string = '600px';
	return (
		<Dialog open={open} maxWidth='xl' onClose={handleMaskingClearClose} >
			<DialogTitle sx={{
				color: '${theme.palette.text.primary}',
				fontFamily: 'Spoqa Han Sans Neo',
				fontStyle: 'normal',
				fontWeight: 700,
				fontSize: '20px', 
			}}>마스킹 해제</DialogTitle>
			<IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 7, color: (theme) => theme.palette.grey[500] }}>
				<GridCloseIcon/>
			</IconButton>
			<DialogContent dividers sx={{flexGrow:1, maxWidth: POPUP_WIDTH}}>
				<ItemGroup>
					<PopupFormControlLabel control={
						<TextField
							size="small" fullWidth disabled
							inputProps={{maxLength: 20}}
							value={maskingClearParam.userId}
							onChange={handleChange("userId")}
						/>
					} label="아이디*" labelPlacement="start"/>
					<PopupFormControlLabel control={
						<TextField
							size="small" type="password"  fullWidth
							value={maskingClearParam.userPwd}
							inputProps={{maxLength: 16}}
							error={paramError.userPwd === '' ? false : true}
							helperText={paramError.userPwd}
							onChange={handleChange("userPwd")}
							onKeyPress={(event) => {
								if(event.key === 'Enter') {
									handleMaskingClear();
								}
							}}
						/>
					} label="비밀번호*" labelPlacement="start"/>
				</ItemGroup>
			</DialogContent>
			<DialogActions>
				<Box sx={{flexGrow:1, width:'100%', display: 'flex', justifyContent: 'center'}}>				
					<StyledButton sx={{background: '#898989'}} onClick={handleMaskingClearClose}>닫기</StyledButton>
					<Box sx={{width:'8px'}}/>
					<StyledButton sx={{background: '#1E4DBA'}} onClick={handleMaskingClear}>확인</StyledButton>
				</Box>
			</DialogActions>
			<CommonDialog open={alertOpen} onClose={handleAlertClose} content={alertCont}/>
		</Dialog>
	)
}