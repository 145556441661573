import { FC, ReactNode, Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Status500 from 'src/content/pages/Status/Status500';
import Status from 'src/content/pages/Status';

// interface FallbackProps {
//   children?: ReactNode;
// }

// const Fallback: FC<FallbackProps> = ({ children }) => {
const Fallback = ({ error, resetErrorBoundary }) => {

  const {response} = error;
  const {status, data} = response;

  return <Status status={status} data={data}/>;

};

// FallbackComponent.propTypes = {
//   // children: PropTypes.node
// };

export default Fallback;
