import { useContext , Suspense} from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SidebarContext } from 'src/contexts/SidebarContext';
import Logo from 'src/components/Logo';

import { Box, Drawer, Hidden } from '@mui/material';

import { styled } from '@mui/material/styles';
import SidebarMenu from './SidebarMenu';
import { useRecoilValue } from 'recoil';
import { getMenuList } from 'src/recoil/selectors';
import Loader from 'src/content/dashboards/Dashboard/suspenseLoader/Loader';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;

        position: fixed;
        z-index: 10;
        // border-top-right-radius: ${theme.general.borderRadius};
        // border-bottom-right-radius: ${theme.general.borderRadius};
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        
        // @media (min-width: ${theme.breakpoints.values.lg}px) {
        //     position: fixed;
        //     z-index: 10;
        //     // border-top-right-radius: ${theme.general.borderRadius};
        //     // border-bottom-right-radius: ${theme.general.borderRadius};
        //     border-top-right-radius: 0;
        //     border-bottom-right-radius: 0;
        // }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        // height: 88px;
        align-items: center;
        // margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
        // border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();

  return (
    <>
      {/* <Hidden lgDown> */}
        <SidebarWrapper>
          <Scrollbars autoHide>
            <TopSection>
              <Logo />
            </TopSection>

            <Suspense fallback={<Loader/>}>
              <SidebarMenu />
            </Suspense>
        

          </Scrollbars>
        </SidebarWrapper>
      {/* </Hidden> */}


      {/* <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper>
            <Scrollbars autoHide>
              <TopSection>
                <Logo />
              </TopSection>
              <SidebarMenu />
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden> */}
    </>
  );
}

export default Sidebar;
