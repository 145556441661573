import { Box, FormControlLabel, styled } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
    	maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

let PopupFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	// width : '250px',
	width : 'auto',
	margin : '4px 10px 4px 0px',
	flexDirection: 'column-reverse',
	alignItems: 'baseline',
	
	'& .MuiFormControlLabel-label': {
		marginBottom: '5px'
	},
	'& .MuiInputBase-input': {
		padding: '10px',
	},
	'& .MuiTextField-root': {
		width: '220px',
		background: theme.palette.background.default,
		'.Mui-disabled' : {
			background: theme.palette.background.default,
		}
	},
  }));

const Title = styled(Box)(({ theme }) => ({
  fontWeight: '700',
  fontSize: '14px',
  color: theme.palette.primary.main,
  fontFamily: 'SpoqaHanSansNeo',
  marginBottom: '5px',
  marginTop: '10px',
  width: '100%',
}));

function ItemGroup(props:any) {
	  	

    
	return (

        <Box width={'100%'} sx={{display: props.display}} marginTop={props.title != null ? props.marginTop : null}>
            
			<Box sx={{display: 'flex'}}>
				{ props.title != null ? <Title> {props.title} </Title> : null }
				
				{ props.control != null ? 
				<Box width={'100%'} sx={{display: 'flex', alignItems: 'flex-end', direction: 'rtl'}}>
					{props.control}
				</Box> : null } 
			</Box>
            
            <Box padding={'10px 10px 10px 10px'}
							width={'100%'}
							borderRadius="5px"
							border="1px solid"
							sx={{backgroundColor: (theme) => theme.header.background,
								 borderColor: (theme) => theme.palette.mode === 'dark' ? '#43495c' : '#E4E4E4'}} >
                {props.children}
            </Box>
        </Box>

		
	);
}

export {ItemGroup, PopupFormControlLabel};