import { bjdongType, EventLvl } from "./CodeConstants";

export const getBjdongCd = (bjdongTypeCd :string, bjdongCd:string) => {
    
    console.debug("common.Utils.getBjdongCd::bjdongTypeCd: %s, bjdongCd: %s", bjdongTypeCd, bjdongCd);
    
    if (bjdongTypeCd)
    {
        if (bjdongTypeCd === bjdongType.SD){
            return bjdongCd.substring(0, 2);
        } else if (bjdongTypeCd === bjdongType.SGG){
            return bjdongCd.substring(0, 5);
        } else if (bjdongTypeCd === bjdongType.UMD){
            return bjdongCd.substring(0, 8);
        }
    }

    return bjdongCd;
}

export const getEventLvlObj = (eventLvl : number) => {
    
    switch (eventLvl) {
        case EventLvl.CRI:	 //심각
            return {name:'심각', backColor: '#EF1515', fontColor: '#EF1515'};
        case EventLvl.MAJ:	//중요
            return {name:'중요', backColor: '#F37F5B', fontColor: '#F37F5B'};
        case EventLvl.MIN:	//관심
            return {name:'관심', backColor: '#157BEF', fontColor: '#157BEF'};
        case EventLvl.WAR:	//경고
            return {name:'경고', backColor: '#15EF69', fontColor: '#15EF69'};
        default:    // case EventLvl.NOR 
            return {name:'보통', backColor: '#000000', fontColor: '#000000'}; // EFA315
    }
}

//아크위험도
export const getRiskLvlObj = (eventLvl : number) => {
    
    switch (eventLvl) {
        case 5:	 //위험
            return {name:'심각', backColor: '#EF1515', fontColor: '#EF1515'};
        case 3:	//점검
            return {name:'중요', backColor: '#EF4915', fontColor: '#EF4915'};
        case 1:	//주의
            return {name:'경고', backColor: '#FFD400', fontColor: '#FFD400'};
        default:    // 양호 
            return {name:'보통', backColor: '#81C147', fontColor: '#81C147'};
    }
}

//RTU상태
export const getRtuCdObj = (cd : string) => {
    
    switch (cd) {
        case 'FAILCONN': //연동실패
            return {name:'연동실패', backColor: '#EF1515', fontColor: '#EF1515'};
        case 'FLT':	//장애
            return {name:'장애', backColor: '#EF1515', fontColor: '#EF1515'};
        case 'NODATA':	//미작동
            return {name:'미작동', backColor: '#EF1515', fontColor: '#EF1515'};
        case 'NOTCONN':	//미연동
            return {name:'미연동', backColor: '#A9A9A9', fontColor: '#A9A9A9'};
        case 'READY':	//준비중
            return {name:'준비중', backColor: '#FFD400', fontColor: '#FFD400'};
        case 'NOR':	//정상('NOR')
            return {name:'정상', backColor: '#000000', fontColor: '#000000'};
        default:    // 일치하는 코드 없을때
            return {name: cd, backColor: '#1598ef', fontColor: '#1598ef'};
    }
}

//설비상태
export const getEquipCdObj = (cd : number) => {
    switch (cd) {
        case 5: //고장
            return {name:'고장', backColor: '#EF1515', fontColor: '#EF1515'};
        case 3:	//경고
            return {name:'경고', backColor: '#EF4915', fontColor: '#EF4915'};
        case 1:	//정상
            return {name:'정상', backColor: '#000000', fontColor: '#000000'};
        case 0:	//준비중
            return {name:'준비중', backColor: '#FFD400', fontColor: '#FFD400'};
        case -1: //미연동
            return {name:'미연동', backColor: '#A9A9A9', fontColor: '#A9A9A9'};
        default: //일치하는 코드 없을때
            return {name: cd, backColor: '#1598ef', fontColor: '#1598ef'};
    }
}